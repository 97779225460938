/* You can add global styles to this file, and also import other style files */
/*Shop related css */
.shop_list_grid .clsproduct_box figure.clsproduct_img a {
    display: inline-block!important;
}
.shop_list_grid .img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
    display: block !important;
    width: 100% !important;
}
.shop_list_grid .clsproduct_box h4 {
    font-family: poppinsbold;
    font-size: 14px;
    line-height: 22px;
    padding: 10px 0;
}
.shop_list_grid .clsproduct_box h4 a {
    color: #4A4A4A;
}
.shop_list_grid .clsproduct_box p.clsproduct_price {
    font-family: poppinsregular;
    font-size: 14px;
}
.clsshop_products_blk .ui-paginator-top.ui-paginator,.clsshop_products_blk .ui-paginator-bottom.ui-paginator {
    border: 1px solid #ccc!important;
    border-radius: 4px!important;
    margin-bottom:10px;
}

.ui-progress-spinner {
    margin: 0 calc(50% - 50px) !important;
}

.ui-dataview-content {
    border: none !important;
}

.ui-dataview-header {
    display: none !important;
}

@import "sass/style";
@import "sass/doomsday";
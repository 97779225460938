/*Starting Common CSS Coading*/
@import "variables";
@import "mixins";

html,
body {
  height: 100%;
}

body {
  background: $primary-bg;
  font-size: 16px;
  margin: 0;
  color: $primary-font-color;
  height: 100%;
  padding: 0;
  text-align: left;
  overflow-x: hidden;
  font-family: $proxima_regular;
  position: relative;
}

@font-face {
  font-family: "proxima_novablack";
  src: url("/assets/fonts/proximanova-black-webfont.woff2") format("woff2"),
  url("/assets/fonts/proximanova-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novabold";
  src: url("/assets/fonts/proximanova-bold-webfont.woff2") format("woff2"),
  url("/assets/fonts/proximanova-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novaextrabold";
  src: url("/assets/fonts/proximanova-extrabld-webfont.woff2") format("woff2"),
  url("/assets/fonts/proximanova-extrabld-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novalight";
  src: url("/assets/fonts/proximanova-light-webfont.woff2") format("woff2"),
  url("/assets/fonts/proximanova-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novaregular";
  src: url("/assets/fonts/proximanova-regular-webfont.woff2") format("woff2"),
  url("/assets/fonts/proximanova-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novasemibold";
  src: url("/assets/fonts/proximanova-semibold-webfont.woff2") format("woff2"),
  url("/assets/fonts/proximanova-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsregular";
  src: url("/assets/fonts/poppins-regular-webfont.woff2") format("woff2"),
  url("/assets/fonts/poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsbold";
  src: url("/assets/fonts/poppins-bold-webfont.woff2") format("woff2"),
  url("/assets/fonts/poppins-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinslight";
  src: url("/assets/fonts/poppins-light-webfont.woff2") format("woff2"),
  url("/assets/fonts/poppins-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinssemibold";
  src: url("/assets/fonts/poppins-semibold-webfont.woff2") format("woff2"),
  url("/assets/fonts/poppins-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

button {
  -webkit-transition: 0.3s all ease 0s;
  -moz-transition: 0.3s all ease 0s;
  -o-transition: 0.3s all ease 0s;
  transition: 0.3s all ease 0s;
  font-size: 16px;
  letter-spacing: 0.5px;
}

html {
  -ms-overflow-style: scrollbar;
}

form,
table,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
div,
dl,
p {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
  color: $yellow_color;
  -webkit-transition: 0.3s all ease 0s;
  -moz-transition: 0.3s all ease 0s;
  -o-transition: 0.3s all ease 0s;
  transition: 0.3s all ease 0s;
}

a:hover,
a:focus {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #333;
  -webkit-transition: 0.3s all ease 0s;
  -moz-transition: 0.3s all ease 0s;
  -o-transition: 0.3s all ease 0s;
  transition: 0.3s all ease 0s;
}

img {
  border: 0;
  vertical-align: middle;
}

/* CSS Clearfix */
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  display: inline-table;
}

/* Hides from IE-mac \*/
* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}


.clsfeature_list {
  li {
    .checkbox {
      input[type="checkbox"],
      input[type="radio"] {
        display: none;
        & + label {
          padding: 0px;
          span {
            float: left;
            width: 24px;
            height: 24px;
            margin: 0px 6px 0 0;
            vertical-align: middle;
            background: url(/assets/images/checkbox_sprite.png) left top no-repeat;
            cursor: pointer;
          }
        }
      }
      input[type="checkbox"] {
        & + label {
          span {
            background: url(/assets/images/checkbox_sprite.png) -9px -46px no-repeat;
            width: 30px;
            height: 22px;
          }
        }
        &:checked + label {
          span {
            background: url(/assets/images/checkbox_sprite.png) -51px -46px no-repeat;
          }
        }
        &:disabled + label {
          span {
            background: url(/assets/images/checkbox_sprite.png) -32px top no-repeat;
            opacity: 0.5;
          }
        }
      }
      input.counseling[type="radio"] {
        & + label {
          span {
            background: url(/assets/images/checkbox_sprite.png) -9px -46px no-repeat;
            width: 30px;
            height: 22px;
            position: relative;
            top: 3px;
          }
        }
        &:checked + label {
          span {
            background: url(/assets/images/checkbox_sprite.png) -51px -46px no-repeat;
          }
        }
        &:disabled + label {
          span {
            background: url(/assets/images/checkbox_sprite.png) -32px top no-repeat;
            opacity: 0.5;
          }
        }
      }
      input[type="radio"] {
        &:disabled + label {
          span {
            background: url(/assets/images/checkbox_sprite.png) left top no-repeat;
            opacity: 0.5;
          }
        }
        & + label {
          line-height: 30px;
          span {
            background: url(/assets/images/checkbox_sprite.png) 0px -4px no-repeat;
            width: 35px;
            height: 35px;
          }
        }
        &:checked + label {
          span {
            background: url(/assets/images/checkbox_sprite.png) -44px -4px no-repeat;
          }
        }
      }
    }
  }
}

.clswrapper {
  .clsheader_top_bg {
    background-color: #ebedf2;
    width: 100%;
    height: 30px;
  }
  .clsmain_header {
    background: #fff;
    .clsheader_top {
      padding: 15px 0;
      border-bottom: 1px solid #e8e8e8;
      .container {
        position: relative;
        .clslogo_blk {
          float: left;
        }
        .clscheckout_lockicon {
          position: relative;
          top: 7px;
          float: right;
        }
        #custom-search-input {
          font-family: secondary_font_family;
          float: right;
          width: 490px;
          margin-top: 3px;
          position: relative;
          right: -21px;
          form {
            .input-group {
              .form-control {
                @include form_control_style();
                font-family: $proxima_regular;
                font-size: 18px;
                &:focus + button {
                  z-index: 3;
                }
                &:focus,
                &:active {
                  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075),
                  0 0 2px rgba(0, 0, 0, 0.2);
                  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075),
                  0 0 2px rgba(0, 0, 0, 0.2);
                }
              }
              button {
                border: 0;
                background: none;
                /** belows styles are working good */
                padding: 2px 5px;
                margin-top: 2px;
                position: relative;
                left: -28px;
                /* IE7-8 doesn't have border-radius, so don't indent the padding */
                margin-bottom: 0;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                border-radius: 3px;
                color: #02416e;
                z-index: 3;
              }
            }
          }
        }

        form.clssearch_xs_form[role="search"] {
          position: absolute;
          top: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
          right: -19px;
          width: 100%;
          padding: 0px;
          margin: 0px;
          z-index: 0;
          width: 38px;
          height: 74px;
          .input-group {
            height: 100%;
            position: relative;
            button,
            input {
              padding: 15px 12px;
              border-radius: 0px;
              border-width: 0px;
              color: rgb(119, 119, 119);
              box-shadow: none;
              outline: none;
            }
            button.clssearch_icon {
              background: none !important;
            }
            button:hover {
              background: none;
            }
            input.form-control {
              padding: 16px 12px;
              font-size: 18pt;
              font-style: italic;
              color: rgb(160, 160, 160);
              box-shadow: none;
              display: none;
              height: 72px;
              opacity: 0;
              transition: all 0.3s ease-in-out;
              &:focus {
                width: 100%;
              }
            }
            button[type="reset"] {
              display: none;
            }
          }
          &.active button[type="reset"],
          &.active input.form-control {
            display: table-cell;
            opacity: 1;
          }
        }
        form.clssearch_xs_form[role="search"].active,
        form.clssearch_xs_form[role="search"].active input.form-control {
          width: 100%;
        }
        form.clssearch_xs_form[role="search"].active {
          left: 50%;
          .input-group {
            .form-control {
              padding: 0 0 0 45px;
            }
            .input-group-btn {
              position: static;
              .clssearch_icon {
                position: absolute;
                left: 0;
                z-index: 9;
              }
            }
          }
        }
      }
    }
    .clsheader_bottom {
      margin-top: 6px;
      box-shadow: 0px 2px 0px 0 rgba(213, 213, 213, 0.3);
      -webkit-box-shadow: 0px 2px 0px 0 rgba(213, 213, 213, 0.3);
      position: relative;
      .clsmega_menu {
        background: none;
        border: 0 none;
        margin-bottom: 0px;
        & .nav,
        & .collapse,
        & .dropup,
        & .dropdown {
          position: static;
          margin-right: 0px;
        }
        & #navbar-collapse-1 {
          padding: 0px;
          float: left;
          @media (max-width: 991px) {
            width: 100%;
            border-top: 1px solid transparent;
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
            display: none !important;
            padding-right: 15px;
            padding-left: 15px;
            .nav.navbar-nav {
              float: none !important;
              margin: 7.5px -15px;
              > li {
                float: none;
                > a {
                  padding-top: 10px;
                  padding-bottom: 10px;
                  padding-left: 0;
                }
              }
              .dropdown.open {
                .dropdown-menu {
                  position: static;
                  width: 100%;
                }
              }
            }
            // &.navbar-collapse.collapse.in {  /* NEW */
            //     display: block!important;
            // }
          }
          .nav.navbar-nav {
            li.dropdown {
              position: relative;
              @media (min-width: 992px) {
                &.open {
                  > ul.dropdown-menu {
                    display: block;
                    @media (max-width: 991px) {
                      width: 100%;
                    }
                  }
                  &::after {
                    background: #f4a619;
                    content: "";
                    position: absolute;
                    height: 2px;
                    left: 0;
                    bottom: 8px;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        #navbar-collapse-1.clsdesktop_navbar {
          @media (max-width: 991px) {
            display: none !important;
          }
        }
        /* Mobile navigation Menu */
        #navbar-collapse-2.navbar-collapse {
          clear: both;
        }
        #navbar-collapse-2.navbar-collapse.clsmobile_navbar {
          display: none !important;
          padding: 0px;
          @media (max-width: 991px) {
            &.in {
              display: block !important;
            }
            .nav.nav-list {
              li {
                position: relative;
                &.tree-toggle{
                  position: relative;
                }
                label.tree-toggle {
                  position: absolute;
                  right: 0;
                  top: 11px;
                  z-index: 9;
                }
                a {
                  color: #00406d;
                  font-family: $poppin_regular;
                  font-size: 15px;
                  letter-spacing: 0.34px;
                  padding-left: 0px;
                  padding-right: 0px;
                  padding-top: 8px;
                  padding-bottom: 8px;
                  &:hover {
                    color: #f4a619;
                    background: none;
                  }
                }
                span.clsclick_arrow {
                  background: url(/assets/images/arrow-right@2x.png) no-repeat right center;
                  width: 20px;
                  height: 20px;
                  display: block;
                  background-size: 8px 12px;
                  position: absolute;
                  right: 0;
                  top: 11px;
                  z-index: 9;

                  cursor: pointer;
                  &.down_arrow {
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    transform: rotate(90deg);
                  }
                }
                .nav.nav-list.tree {
                  border-top: 2px solid #ebedf2;
                  border-bottom: 2px solid #ebedf2;
                  li {
                    a {
                      padding-top: 10px;
                      padding-bottom: 10px;
                    }
                    span.clsclick_arrow {
                      top: 8px;
                    }
                  }
                }
                &.foruser{
                  a {
                    padding-top: 4px;
                    padding-bottom: 4px;
                  }
                }
              }
              
              & {
                li:last-child {
                  .nav.nav-list.tree {
                    border-bottom: 2px solid transparent;
                  }
                }
              }
            }
          }
        }

        position: relative;
        .navbar-header {
          @media (max-width: 991px) {
            float: left;
            .navbar-toggle {
              border: 0 none;
              display: block;
              padding: 9px 0px;
              .icon-bar {
                background-color: #0b426a;
              }
              &:hover,
              &:focus {
                background: none;
              }
              #navbar-close {
                position: relative;
                top: -8px;
                left: 3px;
              }
            }
          }
        }

        .nav.navbar-nav {
          li {
            > a {
              color: #00406d;
              font-family: $poppin_regular;
              font-size: 15px;
              letter-spacing: 0.34px;
              &:hover {
                color: #f4a619;
              }
              @media (max-width: 1199px) {
                padding: 15px 10px;
              }
            }
            &:first-child {
              a {
                // padding-left: 0px;
                @media (max-width: 991px) {
                  padding-left: 15px;
                }
              }
            }
          }

          li.dropdown.open,
          li.dropdown.active {
            a.dropdown-toggle {
              background: none;
              position: relative;
              
            }
          }
        }
        .dropdown-menu {
          font-family: $proxima_regular;
          font-size: 15px;
          left: auto;
          -webkit-box-shadow: 0 3px 4px rgba(168, 168, 168, 0.2);
          box-shadow: 0 3px 4px rgba(168, 168, 168, 0.2);
          border-bottom: 12px solid #f4a619;
          border-top: 0 none;
          border-left: 0 none;
          border-right: 0 none;
        }
        .clsmega_menu_content {
          padding: 20px 30px;
          display: flex;
          @media (max-width: 767px) {
            display: inherit;
          }
          .clsmegamenu_submenu_blk {
            display: inline-block;
            vertical-align: top;
            width: 180px;
            max-width: 180px;
            h4 {
              color: #f4a619;
              font-size: 15px;
              text-transform: uppercase;
              border-bottom: 1.5px solid #ebedf2;
              padding-bottom: 12px;
              margin-bottom: 13px;
              font-family: $proxima_bold;
              letter-spacing: 1.45px;
            }
            ul {
              li {
                line-height: 24px;
                a {
                  color: #00406d;
                  @media (max-width: 767px) {
                    padding-left: 0px !important;
                  }
                  &:hover {
                    color: $yellow_color;
                  }
                }
              }
            }
            @media (max-width: 767px) {
              width: 100%;
              max-width: 100%;
              margin-bottom: 30px;
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
          .clsmenu_two_columns {
            width: 500px;
            max-width: 500px;
            margin-left: 35px;
            @media (max-width: 767px) {
              width: 100%;
              max-width: 100%;
              margin-left: 0px;
            }
            ul {
              li {
                display: inline-block;
                width: 49%;
                @media (max-width: 767px) {
                  display: block;
                  width: 100%;
                }
              }
            }
          }
        }
        .dropdown.yamm-fw {
          .dropdown-menu {
            left: 0;
            right: 0;
          }
        }
        .clscart_menu_blk {
          @media (max-width: 991px) {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-top: 0;
          }
          li.dropdown {
            border: 0px solid transparent;
            @media (max-width: 991px) {
              display: inline-block;
            }
            &:first-child {
              a {
                padding-left: 15px !important;
              }
            }
            .dropdown-toggle {
              span {
                position: relative;
                left: 5px;
              }
              .badge {
                background-color: #f3a61d;
                min-height: 18px;
                border-radius: 50%;
                min-width: 18px;
                line-height: 15px;
                position: relative;
                top: -12px;
                right: 5px;
                left: auto;
                line-height: 16px;
              }
            }
          }
          li.dropdown.open {
            border: 0px solid #f2f3f5;
            // border-bottom: 0 none;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            .dropdown-toggle {
              
            }
            .dropdown-menu {
              max-width: 350px;
              padding: 20px;
              border: 2px solid #f2f3f5;
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              -webkit-box-shadow: none;
              box-shadow: none;
              margin-top: 4px;
              box-shadow: 0px 2px 5px rgba(213, 213, 213, 0.8);
              &.dropdown-cart{
                border: 0px solid #f2f3f5;
                margin-top: 1px;
              }
              h3 {
                font-size: 24px;
                color: #00406d;
                padding-bottom: 20px;
              }
              > ul {
                border-top: 1px solid #e4eaee;
                border-bottom: 2px solid #e4eaee;
                min-width: 250px;
                padding: 20px 0;
                li .item {
                  display: block;
                  padding: 3px 0px 10px 10px;
                  margin: 3px 0;

                  &:after {
                    visibility: hidden;
                    display: block;
                    font-size: 0;
                    content: " ";
                    clear: both;
                    height: 0;
                  }
                  .media-left {
                    padding-right: 15px;
                  }
                  .media-body {
                    .item-info {
                      color: #788995;
                      font-family: $poppin_regular;
                      h4 {
                        font-size: 16px;
                        padding-right: 30px;
                      }
                      p {
                        margin-top: 10px;
                        font-family: $proxima_regular;
                        .clsitem_price {
                          font-size: 14px;
                        }
                        .clsitem_quantity {
                          padding-left: 15px;
                        }
                        .clsdelete {
                          float: right;
                          position: relative;
                          top: -3px;
                          .btn {
                            background: none;
                            font-size: 18px;
                            color: #f4a619;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .clscart_total {
                padding: 17px 0;
                font-size: 18px;
                color: #1b2125;
                border-bottom: 2px solid #e4eaee;
                margin-bottom: 17px;
                span.clstotal_amt {
                  float: right;
                }
              }
              .clscart_checkout_blk {
                ul {
                  li {
                    display: inline-block;
                    vertical-align: middle;
                    width: 49%;
                    a.clsview_cart {
                      color: #f4a619 !important;
                      font-size: 18px;
                      &:hover {
                        color: #1b2125 !important;
                      }
                    }
                    button.btn {
                      @include yellowbutton();
                      float: right;
                    }
                  }
                }
              }
            }
          }
          li.clsuser_dropdown.open {
            position: relative;
            .dropdown-menu {
              right: -2px;
              border:0px none;
              padding: 10px 30px 20px;
              background: #fff;
              box-shadow: 0px 1px 6px rgba(213,213,213,0.8);
              @media (max-width: 767px) {
                position: absolute;
              }
              @media (max-width: 991px){
                display: none !important;
              }
              li {
                padding: 0px 0px;                
              }
              a {
                padding-left: 0px !important;
                padding-right: 0px;
                padding:6px 8px;
                min-width: 150px;
                color: $blue-font_color;
                &:hover {
                  background: none;
                  color: #f4a619;
                }
              }
            }
          }
          .clsuser_dropdown {
            .dropdown-toggle {
              font-family: $poppin_semibold !important;
              font-size: 18px !important;
              &:hover {
                color: $blue-font_color !important;
              }
              span.glyphicon {
                font-size: 14px;
                opacity: 0.4;
                top: 2px;
                margin-left: 0px;
                @media (max-width: 991px){
                  display: none !important;
                }
              }
            }
          }
          .clscart_dropdown {
            a.dropdown-toggle {
              padding: 15px 5px;
              @media (max-width: 991px) {
                padding: 12px 5px !important;
              }
              span {
                img {
                  opacity: 0.5;
                }
              }
            }
            .dropdown-cart {
              margin-top:0px;
              border: 0px solid #ffffff;
              @media (max-width: 767px) {
                position: absolute;
                right: 0px;
                background: #fff;
              }
            }
            &.open {
              a.dropdown-toggle {
                span {
                  img {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .clssign_in_section {
    width: 100%;
    display: table;
    @media (max-width: 767px) {
      display: block !important;
    }
    .clssign_in_content_blk {
      background: url(/assets/images/backg-image.png) no-repeat #fff;
      background-position: left bottom;
      background-size: 100% auto;

      height: 100%;
      width: 59%;
      display: table-cell;
      vertical-align: top;

      @media (max-width: 767px) {
        background: url(/assets/images/backg-image-mobile@2x.png) no-repeat #fff;
        background-position: left bottom;
        background-size: 100% auto;
        display: block;
        width: 100%;
      }

    }

    .clssignin_header {
      font-family: $poppin_regular;
      .navbar-default {
        background: none;
        border: 0 none;
        padding: 30px 0;
        font-size: 18px;
        margin-bottom: 0px;
        @media (max-width: 991px) {
          font-size: 16px;
          @media (max-width: 767px) {
            padding: 15px 0;
          }
        }
        .navbar-header {
          .navbar-toggle {
            border: 0 none;
            margin-top: 0px;
            &:focus,
            &:hover {
              background: none;
            }
            .icon-bar {
              background-color: #0b426a;
            }
          }
          .navbar-brand {
            padding-top: 0px;
          }
        }

        .navbar-collapse {
          .nav.navbar-nav.navbar-right {
            margin-right: 45px;
            @media (max-width: 1199px) {
              margin-right: 0px;
            }
            li {
              a {
                color: $blue-font_color;
                @media (max-width: 991px) {
                  padding: 10px 6px;
                }
                &:hover {
                  color: $yellow_color;
                }
              }
              &.active {
                a {
                  background: none;
                }
              }
            }
          }
        }
      }
    }
    .clssign_in_left_content {
      max-width: 550px;
      margin-left: 95px;
      padding-bottom: 250px;
      padding-top: 65px;
      @media (max-width: 1199px) {
        margin-left: 50px;
        margin-right: 40px;
        @media (max-width: 767px) {
          padding-top: 20px;
          margin-left: 0px;
          margin-right: 0px;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      h1 {
        @include h1_style();
        margin-bottom: 15px;
      }
      p {
        font-size: 22px;
        font-family: $proxima_regular;
      }
      .media {
        margin-top: 50px;
        position: relative;
        z-index: 1;
        padding: 0 0 14px 10px;
        &::before {
          background: url(/assets/images/backg-dots.png) no-repeat;
          background-size: 134px 83px;
          width: 134px;
          height: 83px;
          content: "";
          position: absolute;
          left: 0px;
          bottom: 0px;
          z-index: -1;
          transition: all 0.25s ease-out;
          -webkit-transition: all 0.25s ease-out;
        }
        &:hover {
          &::before {
            left: 14px;
            bottom: 15px;
          }
        }
        @media (max-width: 767px) {
          margin-top: 30px;
        }
        .media-body {
          a {
            p {
              font-size: 18px;
              color: $primary-font-color;
            }
            h4 {
              font-family: $poppin_regular;
              font-size: 19px;
              color: $blue-font_color;
            }
          }
        }
      }
    }
  }
  .sign_in_form_blk {
    background-color: #f2f3f5;
    padding: 32px 80px 30px 80px;
    width: 41%;
    display: table-cell;
    vertical-align: top;
    @media (max-width: 1199px) {
      padding: 32px 40px 30px 40px;
      @media (max-width: 767px) {
        padding: 32px 25px 30px 25px;
        display: block;
        width: 100%;
      }
    }
    .nav-tabs {
      font-family: $proxima_regular;
      font-size: 22px;
      border: 0 none;
      li {
        a {
          color: $grey-title-font-color;
          background: none;
          border: 0 none;
        }
        &.active {
          a {
            background: none;
            border: 0 none;
            border-bottom: 3px solid #f4a619;
            position: relative;
            color: $yellow_color;
          }
        }
      }
    }
    .tab-content {
      .tab-pane {
        form {
          padding-top: 30px;
          .form-group {
            margin-bottom: 20px;
            label {
              @include label_style();
              position: relative;

              span .clspopover_content {
                border: 1px solid #F2F3F5;
                border-radius: 8px;
                background-color: #FFFFFF;
                box-shadow: 0 1px 3px 0 #BCCFDD;
                -webkit-box-shadow: 0 1px 3px 0 #BCCFDD;
                padding: 25px;
                width: 355px;
                position: absolute;
                z-index: 1;
                top: 33px;
                display: none;
              }
              span .clspopover_content:before {
                position: absolute;
                z-index: -1;
                content: "";
                right: 90px;
                top: -8px;
                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent #fff transparent;
                transition-duration: 0.3s;
                transition-property: transform;
              }
              span .clspopover_content p {
                margin-bottom: 25px;
              }
              span .clspopover_content p:last-child {
                margin-bottom: 0px;
              }
              span:hover .clspopover_content {
                display: block;
              }
              @media (max-width: 414px) {
                &.clsinfo_label {
                  display: block;
                }
                &.clsinfo_label span .clspopover_content {
                  width: 100%;
                }
                &.clsinfo_label span .clspopover_content:before {
                  right: calc(50% - 10px);
                }
              }
            }
            .form-control {
              @include form_control_style();
            }
            .clslist_sort {
              @include clslist_sort_style();
            }
            .clsfeature_list li {
              display: inline-block;
              &:last-child {
                margin-left: 15px;
              }
            }
          }
          .clsyellow_btn {
            @include yellowbutton();
            margin-top: 30px;
            font-size: 16px;
          }
          .clsforget_text {
            padding-top: 25px;
            a {
              font-size: 18px;
              color: $yellow_color;
              &:hover {
                color: $primary-font-color;
              }
            }
          }
        }
      }
    }
  }
}

.clsmain_container {
  padding: 30px 0px;
  .clsbreadcrumb {
    font-size: 12px;
    li {
      display: inline-block;
      color: #a9a9a9;
      position: relative;
      color: $yellow_color;
      padding: 0 10px;
      &:first-child {
        padding-left: 0px;
      }
      a {
        color: #0B426A;
        position: relative;
        &:first-child {
          padding-left: 0px;
        }
        &::after {
          background: url(/assets/images/breadcrumb_arrow@2x.png) no-repeat;
          content: "";
          position: absolute;
          width: 5px;
          height: 8px;
          top: 3px;
          right: -14px;
          background-size: 5px 8px;
        }
        &:hover {
          &::before {
            background: #f4a619;
            content: "";
            position: absolute;
            height: 2px;
            left: 0;
            bottom: -3px;
            width: 100%;
          }
        }
      }
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .clssignup_secondary_form_blk {
    margin-top: 20px;
    p {
      line-height: 26px;
    }
    .clssignup_secondary_form_blk_left {
      h1 {
        @include h1_style();
        padding: 20px 0 25px;
      }
    }
    .clssignup_secondary_form_blk_right {
      @media (max-width: 767px) {
        margin-top: 40px;
      }
      .clssignup_step2_form {
        color: $grey-font-color;
        @include whitebox();
        padding: 50px;
        @media (max-width: 767px) {
          padding: 20px;
        }
        form {
          .clsfeature_list{
            label{
              color: #02416e;
            }
          }
          .form-group {
            position: relative;
            label {
              @include label_style();
              color: #02416e;
              span {
                font-size: 14px;
                color: $grey-title-font-color;
              }
            }
            .form-control {
              @include form_control_style();
            }
            .clsfeature_list {
              position: absolute;
              top: -10px;
              right: 0px;
              .checkbox {
                input[type="checkbox"] {
                  & + label {
                    
                    span {
                      background: url(/assets/images/checkbox_small.png) 0px 1px no-repeat;
                      width: 14px;
                      height: 15px;
                      position: relative;
                      top: 2px;
                    }
                  }
                  &:checked + label {
                    span {
                      background: url(/assets/images/checkbox_small.png) -16px 0px no-repeat;
                    }
                  }
                  &:disabled + label {
                    span {
                      background: url(/assets/images/checkbox_small.png) -32px top no-repeat;
                      opacity: 0.5;
                    }
                  }
                }
                
              }
            }
          }
          .clssignup_step2_form_content {
            padding: 15px 0 0;
            .clssignup_step2_form_content_btn {
              margin-top: 20px;
              .clsyellow_btn {
                @include yellowbutton();
                display: inline-block;
                vertical-align: middle;
                min-width: 145px;
              }
              span.clslater_text {
                display: inline-block;
                margin-left: 20px;
                vertical-align: middle;
                a {
                  color: $grey-font-color;
                  font-size: 14px;
                }
              }
            }
          }
        }
        p {
          padding-bottom: 20px;
        }
      }
    }
  }
  .clscheckout_form_blk_section {
    .clscheckout_form {
      @include whitebox();
      padding: 28px 35px;
      @media (max-width: 1199px) {
        padding: 20px;
      }
      .clscheckout_menu_order {
        ul {
          margin-bottom: 25px;
          @media (max-width: 767px) {
            text-align: center;
          }
          li {
            display: inline-block;
            padding: 0 23px;
            margin-bottom: 20px;
            @media (max-width: 1199px) {
              padding: 0 10px;
            }
            a {
              font-size: 22px;
              position: relative;
              display: inline-block;
              color: $grey-title-font-color;
            }
            &.active {
              a {
                color: $blue-font_color;
                &::after {
                  background: #f4a619;
                  content: "";
                  height: 3px;
                  width: 100%;
                  position: absolute;
                  left: 0;
                  bottom: -10px;
                }
              }
            }
            &:first-child {
              padding-left: 0px;
            }
          }
        }
      }
      form {
        .form-group {
          label {
            @include label_style();
          }
          .form-control {
            @include form_control_style();
          }
          .clslist_sort {
            @include clslist_sort_style();
          }
          p {
            margin-top: 6px;
            a {
              color: $yellow_color;
              &:hover {
                color: #333;
              }
            }
          }
          // .clsfeature_list{
          //     .checkbox{
          //         input[type="checkbox"]{
          //             & + label{
          //                 font-size: 12.5px;
          //                 span{
          //                     background: url(../images/checkbox_small.png) 0px 1px no-repeat;
          //                     width: 14px;
          //                     height: 15px;
          //                 }
          //             }
          //             &:checked + label{
          //                 span{
          //                     background: url(../images/checkbox_small.png) -16px 0px no-repeat;
          //                 }
          //             }
          //             &:disabled + label{
          //                 span {
          //                     background: url(../images/checkbox_small.png) -32px top no-repeat;
          //                     opacity: 0.5;
          //                 }
          //             }
          //         }
          //     }
          // }
        }
        .clscheckout_address {
          position: relative;
          color: #788995;
          p {
            margin-bottom: 25px;
            line-height: 24px;
            strong {
              font-family: $proxima_semibold;
              font-weight: normal;
            }
            @media (max-width: 767px) {
              font-size: 13px;
            }
          }
          a.clsedit_address_link {
            position: absolute;
            top: 0px;
            right: 0px;
            color: $yellow_color;
            &:hover {
              color: $grey-font-color;
            }
            @media (max-width: 568px) {
              position: static;
            }
          }
          .clsred_alert {
            background-color: #ff5757;
            color: #fff;
            font-size: 14px;
            font-family: $proxima_regular;
            letter-spacing: 1px;
            padding: 3px 10px;
            display: inline-block;
          }
        }
        .clscheckout_btn_blk {
          display: table;
          width: 100%;
          padding-top: 30px;
          @media (max-width: 414px) {
            text-align: center;
          }
          .clsback_link {
            display: table-cell;
            vertical-align: middle;
            @media (max-width: 414px) {
              display: inline-block;
              margin-bottom: 15px;
              width: 100%;
            }
            a {
              color: #788995;
              span.glyphicon {
                font-size: 12px;
                margin-right: 2px;
              }
              &:hover {
                color: $yellow_color;
              }
            }
          }
          .clsyellow_btn,
          .clsgrey_btn {
            @include yellowbutton();
            display: table-cell;
            float: right;
            min-width: 250px;
            @media (max-width: 568px) {
              min-width: auto;
              @media (max-width: 414px) {
                display: inline-block;
                float: none;
                text-align: center;
                min-width: 250px;
              }
            }
          }
          .clsgrey_btn {
            @include greybutton();
          }
        }
        .clslreview_order {
          ul {
            li {
              padding-bottom: 20px;
              h4 {
                font-size: 18px;
                color: #1b2125;
                border-bottom: 1px solid #e4eaee;
                padding-bottom: 20px;
                margin-bottom: 25px;
                @media (max-width: 767px) {
                  font-size: 16px;
                }
                span {
                  float: right;
                  a {
                    color: $yellow_color;
                    font-size: 16px;
                    &:hover {
                      color: $primary-font-color;
                    }
                  }
                }
              }
              p {
                color: #788995;
              }
            }
          }
        }
        .clsreview_info {
          display: table;
          width: 100%;
          font-size: 15px;
          padding: 25px 0;
          color: #788995;
          span {
            display: table-cell;
            vertical-align: middle;
            &:last-child {
              padding-left: 10px;
            }
          }
        }
        .clscheckout_review_check {
          font-size: 18px;
        }
      }
    }
    .clsshipping_form {
      .clscheckout_address {
        font-family: $poppin_regular;
        color: #788995;
        p {
          strong {
            font-family: $poppin_semibold !important;
          }
        }
      }
      .clsshipping_option_blk {
        font-size: 18px;
        .clsfeature_list {
          border-top: 2px solid #e4eaee;
          margin-top: 5px;
          li {
            .checkbox {
              margin: 0px;
              input[type="radio"] {
                & + label {
                  display: block;
                  color: #1b2125;
                  background: #fff;
                  border-bottom: 2px solid #e4eaee;
                  padding: 10px;
                  letter-spacing: 0px;
                  span {
                    margin-right: 20px;
                  }
                  strong {
                    float: right;
                    font-weight: normal;
                    @media (max-width: 480px) {
                      float: none;
                      display: inline-block;
                      margin-left: 15px;
                    }
                  }
                }
                &:checked + label {
                  background-color: #f8f9fa;
                }
              }
            }
          }
        }
      }
    }
    .clsorder_summary_blk {
      @include whitebox();
      padding: 28px 35px;
      @media (max-width: 1199px) {
        padding: 20px;
        @media (max-width: 991px) {
          margin-top: 25px;
        }
      }
      h3 {
        font-size: 24px;
        color: #00406d;
        padding-bottom: 20px;
        font-family: $poppin_regular;
      }
      ul {
        li {
          padding: 15px 0;
          .media {
            display: block;
            margin: 3px 0;
            .media-left {
              padding-right: 20px;
            }
            .media-middle {
              .item-info {
                color: #788995;
                h4 {
                  font-size: 16px;
                  font-family: $poppin_regular;
                }
                p {
                  margin-top: 10px;
                  .clsitem_price {
                    font-size: 14px;
                  }
                  .clsitem_quantity {
                    padding-left: 15px;
                  }
                  .clsdelete {
                    float: right;
                    position: relative;
                    top: -3px;
                    .btn {
                      background: none;
                      font-size: 18px;
                      color: #f4a619;
                    }
                  }
                }
              }
            }
            .clsred_text {
              color: #ff5757;
              font-size: 14px;
              padding-top: 10px;
            }
          }
        }
      }
      .clsdiscount_blk {
        border-top: 1px solid #e4eaee;
        border-bottom: 1px solid #e4eaee;
        padding: 20px 0;
        .input-group {
          .form-control {
            @include form_control_style();
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }
          .input-group-btn {
            .btn {
              height: 40px;
              border-color: #e5e5e5;
              box-shadow: inset 0 0 0.5px 0 rgba(0, 0, 0, 0.25);
              -webkit-box-shadow: inset 0 0 0.5px 0 rgba(0, 0, 0, 0.25);
              -moz-box-shadow: inset 0 0 0.5px 0 rgba(0, 0, 0, 0.25);
              font-size: 16px;
              color: $yellow_color;
            }
          }
        }
      }
      .clscart_total {
        padding: 17px 0;
        font-size: 18px;
        color: #1b2125;
        border-bottom: 2px solid #e4eaee;
        .clstotal_amt {
          float: right;
        }
      }
      .clsfinal_total {
        border: 0 none;
        padding-bottom: 0px;
      }
    }
  }
  /*My account page styles*/
  .clsmyaccount_section {
    /*My account order history page styles*/
    .clsuser_help_blk {
      display: table;
      width: 100%;
      @media (max-width: 991px) {
        margin-top: 30px;
      }
      h1 {
        @include h1_style();
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        @media (max-width: 991px) {
          display: block;
        }
      }
      .clsuser_help_div {
        display: table-cell;
        vertical-align: middle;
        @media (max-width: 991px) {
          display: block;
        }
        ul {
          float: right;
          max-width: 800px;
          margin: 30px auto;
          text-align: center;
          vertical-align: middle;
          @media (max-width: 991px) {
            float: none;
          }
          li {
            display: inline-block;
            vertical-align: top;
            .clsuser_help_box {
              background: #fff;
              border: 1px solid #ddd;
              border-radius: 6px;
              width: 130px;
              height: 130px;
              position: relative;
              display: table;
              text-align: center;
              margin: 0 13px 0px;
              @media (max-width: 767px) {
                margin-bottom: 15px;
              }
              a {
                display: table-cell;
                vertical-align: middle;
                height: 100%;
                figure {
                  margin-bottom: 30px;
                }
                h5 {
                  font-family: $poppin_bold;
                  font-size: 11.5px;
                  color: #0b426a;
                  margin-top: 15px;
                  position: absolute;
                  bottom: 20px;
                  left: 0px;
                  right: 0px;
                  margin: 0 auto;
                  letter-spacing: 0.2px;
                }
              }
              &:hover {
                border: 1px solid $yellow_color;
                -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075),
                0 0 2px rgba(244, 166, 25, 0.7);
                -moz-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075),
                0 0 2px rgba(244, 166, 25, 0.7);
              }
            }
            &:last-child .clsuser_help_box {
              margin-right: 0px;
              @media (max-width: 767px) {
                margin-right: 13px;
              }
            }
          }
        }
      }
    }
    .clsmy_account_main_content_blk {
      .clsmy_account_menus {
        @include whitebox();
        padding: 30px;
        font-size: 22px;
        @media (max-width: 991px) {
          font-size: 19px;
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }
        ul {
          li {
            padding: 15px 0;
            a {
              color: $grey-title-font-color;
            }
            &.active a {
              color: $blue-font_color;
              position: relative;
              &::after {
                background: #f4a619;
                height: 3px;
                content: "";
                width: 100%;
                position: absolute;
                left: 0;
                bottom: -10px;
              }
            }
            &:first-child {
              padding-top: 0px;
            }
          }
        }
      }
      .clsmyaccount_menu_content_blk {
        @include whitebox();
        padding: 30px;
        .table-responsive {
          table {
            thead {
              font-size: 18px;
              color: $grey-title-font-color;
              text-transform: uppercase;
              letter-spacing: 0.41px;
              font-family: $proxima_semibold;
              th {
                font-weight: normal;
                border: 0 none;
                padding-bottom: 25px;
              }
            }
            tbody {
              tr {
                box-shadow: 0 2px 4px 0 rgba(222, 222, 222, 0.5);
                -moz-box-shadow: 0 2px 4px 0 rgba(222, 222, 222, 0.5);
                -webkit-box-shadow: 0 2px 4px 0 rgba(222, 222, 222, 0.5);
                &:last-child {
                  box-shadow: none;
                  -moz-box-shadow: none;
                  -webkit-box-shadow: none;
                }
                td {
                  vertical-align: middle;
                  border: 0 none;
                  padding: 25px 10px;
                  .media {
                    display: table;
                    .media-left {
                      padding-right: 20px;
                    }
                    .media-body {
                      h4 {
                        font-size: 16px;
                        a {
                          color: $primary-font-color;
                          &:hover {
                            color: $yellow_color;
                          }
                        }
                      }
                    }
                  }
                  .clsorder_Status {
                    border-radius: 18px;
                    background-color: #b4c3da;
                    font-family: $proxima_semibold;
                    font-size: 14px;
                    color: $primary-font-color;
                    text-transform: uppercase;
                    font-weight: normal;
                    padding: 5px 20px;
                    letter-spacing: 0.41px;
                    width: 100%;
                    max-width: 200px;
                    //text-overflow: ellipsis;
                    //white-space: nowrap;
                    //overflow: hidden;
                    text-align: center;
                    &.clsdelivered {
                      background-color: #f3a61d;
                    }
                  }
                  .clsorder_total {
                    font-family: $proxima_semibold;
                    font-size: 18px;
                    color: $primary-font-color;
                  }
                  a {
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
      .clspagination_blk.clspagination_blk_bottom {
        margin-top: 25px;
        .clspagination_div {
          display: inline-block;
          vertical-align: middle;
          width: 100%;
          @media (max-width: 1199px) {
            width: 70%;
            @media (max-width: 767px) {
              display: block;
              width: 100%;
            }
          }
          nav {
            text-align: right;
            @media (max-width: 767px) {
              text-align: left;
              margin-top: 10px;
            }
            p {
              display: inline-block;
              vertical-align: middle;
              font-family: $poppin_semibold;
              margin-right: 25px;
              span {
                font-family: $poppin_regular;
                padding: 0 5px;
                font-size: 14px;
                a {
                  color: $primary-font-color;
                  font-family: $proxima_regular;
                  &.active {
                    color: $yellow_color;
                  }
                }
              }
            }
            .pagination {
              display: inline-block;
              vertical-align: middle;
              margin: 0px;
              li {
                &:first-child {
                  a {
                    border-left: 1px solid #dedede;
                  }
                }
                &:last-child {
                  a {
                    border-right: 1px solid #dedede;
                  }
                }
                a {
                  color: $primary-font-color;
                  border-left: 0 none;
                  border-right: 0 none;
                  span.glyphicon {
                    color: $yellow_color;
                    font-size: 11px;
                  }
                }
                > span.dots {
                  border-right: 1px solid #dedede;
                  position: relative;
                  float: left;
                  padding: 6px 12px;
                  line-height: 1.42857143;
                  z-index: 2;
                  &:hover {
                    background-color: transparent;
                  }
                }
                &.active {
                  a {
                    color: $yellow_color;
                    background: none;
                    border-top: 1px solid #dedede;
                    border-bottom: 1px solid #dedede;
                  }
                }
                &.disabled {
                  a {
                    cursor: default !important;
                    color: #dedede;
                    span.glyphicon {
                      color: #dedede;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .clsacc_medication_form {
        .table-responsive {
          table {
            tbody {
              tr {
                td {
                  padding: 33px 10px;
                }
              }
            }
          }
        }
      }
    }
    .clssignup_secondary_form_blk {
      margin-top: 0px;
    }
  }
  /*My account order history page styles end*/
  .clsorder_details_section {
    /*My account order detail page styles*/
    .clsaccount_top_backto_btn {
      margin: 30px 0;
      a {
        color: #788995;
        .glyphicon {
          font-size: 12px;
          margin-right: 2px;
        }
        &:hover {
          color: $yellow_color;
        }
      }
    }
    .clsuser_help_blk {
      margin-bottom: 35px;
      h1 {
        text-align: left;
      }
      .clsorder_details_btn_blk {
        float: right;
        @media (max-width: 991px) {
          float: none;
          margin: 20px 0;
        }
        .clsyellow_btn {
          @include yellowbutton();
          min-width: 195px;
          @media (max-width: 767px) {
            margin-bottom: 10px;
          }
        }
      }
    }
    .clssave_remove_txt {
      font-size: 18px;
      padding: 50px 0 30px;
    }
  }
  /*My account order detail page styles end*/

  /*My account profile page styles*/
  .clsaccount_profile_blk {
    .clsaccount_profile_form {
      position: relative;
      @media (max-width: 767px) {
        padding: 50px 20px !important;
      }
      h3 {
        font-size: 18px;
        color: $grey-title-font-color;
        font-family: $proxima_semibold;
        text-transform: uppercase;
      }
      .clsyellow_btn {
        min-width: 245px;
        @include yellowbutton();
      }
      .clsacc_profile_top_btn {
        position: absolute;
        top: 30px;
        right: 50px;
        @media (max-width: 767px) {
          right: 21px;
          @media (max-width: 479px) {
            position: static;
            margin-top: 40px;
          }
        }
      }
      form {
        margin-top: 45px;
        @media (max-width: 767px) {
          margin-top: 25px;
        }
        .clsacc_profile_save_btn_div {
          display: table;
          width: 100%;
          margin-top: 20px;
          .clsyellow_btn {
            float: right;
          }
        }
      }
    }
  }
  /*My account profile page styles end*/

  /*My account address page styles*/
  .clsaccount_address_blk {
    .clsaccount_address_form {
      position: relative;
      @media (max-width: 767px) {
        padding: 50px 20px !important;
      }
      h3 {
        font-size: 18px;
        color: $grey-title-font-color;
        font-family: $proxima_semibold;
        text-transform: uppercase;
      }
      .clsyellow_btn {
        min-width: 245px;
        @include yellowbutton();
      }
      .clsacc_profile_top_btn {
        position: absolute;
        top: 30px;
        right: 50px;
        @media (max-width: 767px) {
          right: 21px;
          @media (max-width: 479px) {
            position: static;
            margin-top: 40px;
          }
        }
      }
      .clsacc_profile_top_btn_grey {
        @include greybutton();
        position: absolute;
        top: 30px;
        right: 50px;
        @media (max-width: 767px) {
          right: 21px;
          @media (max-width: 479px) {
            position: static;
            margin-top: 40px;
          }
        }
      }
      form {
        margin-top: 45px;
        @media (max-width: 767px) {
          margin-top: 25px;
        }
        .clsacc_profile_save_btn_div {
          display: table;
          width: 100%;
          margin-top: 20px;
          .clsyellow_btn {
            float: right;
          }
        }
        .clslreview_order {
          ul {
            li {
              padding-bottom: 20px;
              h4 {
                font-size: 18px;
                color: #1b2125;
                border-bottom: 1px solid #e4eaee;
                padding-bottom: 20px;
                margin-bottom: 25px;
                span {
                  float: right;
                  font-size: 18px;
                  a {
                    color: $yellow_color;
                    font-size: 16px;
                    margin: 0 10px;
                    &:last-child {
                      margin-right: 0px;
                    }
                    &:hover {
                      color: $primary-font-color;
                    }
                  }
                }
              }
              p {
                color: #788995;
              }
              .clscheckout_address {
                color: #788995;
                p {
                  strong {
                    font-family: $proxima_semibold;
                    font-weight: normal;
                  }
                }
              }
            }
          }
        }
        .clslist_sort {
          @include clslist_sort_style();
        }
        .clsadd_btn_blk {
          display: table;
          width: 100%;
          text-align: right;
          margin-bottom: 50px;
          a {
            margin-right: 20px;
          }
          .clsyellow_btn {
            min-width: 170px;
          }
        }
      }
    }
  }
  /*My account address page styles end*/

  /*Order confirmation page styles*/
  .clsorder_confirmation {
    @include whitebox();
    padding: 50px 20px 80px;
    margin: 20px 0;
    text-align: center;
    .clsorder_confirmation_div {
      max-width: 490px;
      margin: 0 auto;
      h3 {
        font-family: $poppin_semibold;
        font-size: 24px;
        color: $blue-font_color;
        margin-bottom: 15px;
      }
      h4 {
        font-family: $poppin_regular;
        font-size: 22px;
        color: $blue-font_color;
        margin-bottom: 50px;
        margin-top: 20px;
      }
      p {
        font-size: 18px;
        color: $primary-font-color;
        strong {
          font-family: $proxima_bold;
          font-weight: normal;
        }
      }
      .clsconfirmation_btn_blk {
        margin-top: 40px;
        .clsyellow_btn {
          @include yellowbutton();
          padding-left: 45px;
          padding-right: 45px;
          margin: 0 5px;
          @media (max-width: 568px) {
            display: block;
            margin: 0 0 10px 0;
          }
        }
      }
    }
  }
  /*Order confirmation page styles end*/

  /*Account payment method page styles*/
  .clsaccount_payment_blk {
    .clsaccount_payment_form {
      padding: 20px 0 30px !important;
      form {
        .table-responsive {
          @media (max-width: 767px) {
            border: 0 none;
          }
          table {
            thead {
              th {
                text-transform: uppercase;
                font-family: $proxima_bold;
                font-weight: normal;
                color: $grey-title-font-color;
                border: 0 none;
                padding-bottom: 25px;
                vertical-align: middle;
                .clsacc_profile_top_btn {
                  @include yellowbutton();
                  float: right;
                }
                .clsacc_profile_top_btn_grey {
                  @include greybutton();
                }
                &:first-child {
                  padding-left: 30px;
                }
                &:last-child {
                  text-align: right;
                  padding-right: 30px;
                }
              }
            }
            tbody {
              color: $primary-font-color;
              tr {
                box-shadow: 0 3px 4px 0 rgba(222, 222, 222, 0.5);
                -moz-box-shadow: 0 3px 4px 0 rgba(222, 222, 222, 0.5);
                -webkit-box-shadow: 0 3px 4px 0 rgba(222, 222, 222, 0.5);
                td {
                  vertical-align: middle;
                  border: 0 none;
                  padding: 30px;
                  .clsfeature_list {
                    li {
                      .checkbox {
                        margin: 8px 0 0;
                        input[type="radio"] {
                          & + label {
                            display: block;
                            color: #1b2125;
                            background: #fff;
                            letter-spacing: 0px;
                            span {
                              margin-right: 20px;
                            }
                            strong {
                              float: right;
                              font-weight: normal;
                              @media (max-width: 480px) {
                                float: none;
                                display: inline-block;
                                margin-left: 15px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  &:first-child {
                    padding-left: 30px;
                  }
                  &:last-child {
                    text-align: right;
                    padding-right: 30px;
                  }
                  > table {
                    width: 100%;
                    > tbody {
                      > tr {
                        box-shadow: none;
                        -moz-box-shadow: none;
                        -webkit-box-shadow: none;
                        td.clspay_add_address_checkbox {
                          vertical-align: top;
                          .clsfeature_list {
                            li {
                              .checkbox {
                                margin-top: 28px;
                              }
                            }
                          }
                        }
                        > td {
                          padding: 15px !important;
                          h4 {
                            font-size: 18px;
                            color: #1b2125;
                            border-bottom: 1px solid #e4eaee;
                            padding-bottom: 20px;
                            text-align: left;
                          }
                          .clspayment_add_address_form {
                            text-align: left;
                            .clslist_sort {
                              @include clslist_sort_style();
                            }
                            .clsadd_btn_blk {
                              display: table;
                              width: 100%;
                              text-align: right;
                              a {
                                margin-right: 20px;
                              }
                              .clsyellow_btn {
                                @include yellowbutton();
                                min-width: 170px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*Account payment method page styles end*/
  .clsrx_history_blk {
    .table-responsive {
      table {
        tbody {
          tr {
            td {
              padding: 10px !important;
            }
          }
        }
      }
    }
  }
  /*Account payment method page styles end*/

  /*Medication request page styles*/
  .clsmedication_request_section {
    .clslmedication_request_content_blk {
      margin-top: 25px;
      .clslmedication_request_content_left {
        h1 {
          @include h1_style();
          line-height: 58px;
          margin-bottom: 20px;
        }
      }
      .clsmedication_request_form {
        @include whitebox();
        padding: 60px 50px;
        @media (max-width: 767px) {
          margin-top: 30px;
          padding: 30px 20px;
        }
        form {
          .form-group {
            label {
              @include label_style();
            }
            .form-control {
              @include form_control_style();
            }
            .clsmedication_btn_div {
              display: table;
              width: 100%;
              .clsyellow_btn {
                @include yellowbutton();
                display: table-cell;
              }
              span {
                display: table-cell;
                text-align: right;
                @media (max-width: 991px) {
                  display: block;
                  text-align: left;
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*Medication request page styles end*/

  /*Policy pages styles*/
  .clspolicy_section {
    .clspolicy_content {
      @include whitebox();
      padding: 60px 50px;
      font-family: $proxima_regular;
      color: #666666;
      font-size: 18px;
      p {
        margin-bottom: 15px;
        line-height: 30px;
        strong {
          font-family: $poppin_semibold;
          font-weight: normal;
          font-size: 16px;
        }
      }
      hr {
        margin-top: 35px;
        margin-bottom: 35px;
      }
      ul {
        li {
          margin-bottom: 18px;
          p {
            margin-bottom: 0px;
            em {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  /*Policy pages styles end*/

  /*Forgot and reset pages styles*/
  .forgot_form_blk {
    margin: 30px 0;
    @media (max-width: 767px) {
      margin: 0;
    }
    h1 {
      @include h1_style();
    }
    .forgot_form {
      @include whitebox();
      max-width: 700px;
      margin: 30px auto 0;
      padding: 60px 50px;
      @media (max-width: 767px) {
        padding: 30px 20px;
      }
      form {
        .form-group {
          label {
            @include label_style();
          }
          .form-control {
            @include form_control_style();
          }
          .clsyellow_btn {
            @include yellowbutton();
            min-width: 200px;
          }
        }
      }
    }
  }
  /*Forgot and reset pages styles end*/
}

/*404 pages styles*/
.cls404_section {
  background: url(/assets/images/404_bg@2x.png) no-repeat;
  background-position: left bottom;
  background-size: 100% auto;
  text-align: center;
  padding: 150px 0 340px;
  @media (max-width: 1399px) {
    padding: 120px 0 270px;
    @media (max-width: 1199px) {
      padding: 120px 0 240px;
      @media (max-width: 991px) {
        padding: 120px 0 180px;
        @media (max-width: 767px) {
          background: url(/assets/images/backg-image-mobile@2x.png) no-repeat #fff;
          background-position: left bottom;
          background-size: 100% auto;
          display: block;
          width: 100%;
          padding: 75px 0 340px;
          @media (max-width: 568px) {
            padding: 75px 0 300px;
            @media (max-width: 480px) {
              padding: 75px 0 275px;
              @media (max-width: 414px) {
                padding: 75px 0 250px;
                @media (max-width: 360px) {
                  padding: 75px 0 200px;
                }
              }
            }
          }
        }
      }
    }
  }

  h1 {
    @include h1_style();
    font-size: 60px;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      font-size: 60px;
    }
  }
  p {
    font-size: 22px;
  }
  .clsyellow_btn {
    @include yellowbutton();
    min-width: 200px;
    margin-top: 20px;
  }
}

/*404 pages styles end*/
/*Inventory page styles*/
.clsinventory_section {
  background: #fff;
  text-align: center;
  font-size: 18px;
  padding-bottom: 70px;
  h1 {
    @include h1_style();
    text-align: center;
    font-size: 34px;
    margin-top: 10px;
  }
  .clsinventory_search_blk {
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(
                    top,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 20%,
                    rgba(235, 237, 242, 1) 20%,
                    rgba(235, 237, 242, 1) 80%,
                    rgba(255, 255, 255, 1) 80%,
                    rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-gradient(
                    left top,
                    left bottom,
                    color-stop(0%, rgba(255, 255, 255, 1)),
                    color-stop(20%, rgba(255, 255, 255, 1)),
                    color-stop(20%, rgba(235, 237, 242, 1)),
                    color-stop(80%, rgba(235, 237, 242, 1)),
                    color-stop(80%, rgba(255, 255, 255, 1)),
                    color-stop(100%, rgba(255, 255, 255, 1))
    );
    background: -webkit-linear-gradient(
                    top,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 20%,
                    rgba(235, 237, 242, 1) 20%,
                    rgba(235, 237, 242, 1) 80%,
                    rgba(255, 255, 255, 1) 80%,
                    rgba(255, 255, 255, 1) 100%
    );
    background: -o-linear-gradient(
                    top,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 20%,
                    rgba(235, 237, 242, 1) 20%,
                    rgba(235, 237, 242, 1) 80%,
                    rgba(255, 255, 255, 1) 80%,
                    rgba(255, 255, 255, 1) 100%
    );
    background: -ms-linear-gradient(
                    top,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 20%,
                    rgba(235, 237, 242, 1) 20%,
                    rgba(235, 237, 242, 1) 80%,
                    rgba(255, 255, 255, 1) 80%,
                    rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 20%,
                    rgba(235, 237, 242, 1) 20%,
                    rgba(235, 237, 242, 1) 80%,
                    rgba(255, 255, 255, 1) 80%,
                    rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
    margin-top: 40px;
    margin-bottom: 60px;
    text-align: center;
    font-size: 18px;
    .clsinventory_search_box {
      @include whitebox();
      text-align: center;
      padding: 50px 30px;
      font-size: 18px;
      form {
        margin-bottom: 30px;
        .form-group {
          @media (max-width: 767px) {
            margin-bottom: 15px;
          }
          .input-group {
            width: 410px;
            @media (max-width: 767px) {
              width: 100%;
              max-width: 410px;
              margin: 0 auto;
            }
            .input-group-addon {
              background: none;
              border-color: #dbd2d2;
              padding: 6px 5px;
              @media (max-width: 767px) {
                padding: 6px 15px;
              }
            }
            .form-control {
              @include form_control_style();
              border-color: #dbd2d2;
              border-left: 0 none;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              height: 51px;
              padding-left: 0px;
            }
          }
        }
        .clsyellow_btn {
          @include yellowbutton();
          width: 195px;
        }
      }
      ul {
        text-align: center;
        margin-top: 30px;
        li {
          display: inline-block;
          margin: 0 7px;
        }
      }
    }
  }
  .clsrequest_btn {
    margin-top: 25px;
    .clsyellow_btn {
      @include yellowbutton();
    }
  }
  .clsinventory_list {
    text-align: left;
    font-size: 16px;
    > ul {
      columns: 3;
      -webkit-columns: 3;
      -moz-columns: 3;
      white-space: nowrap;
      @media (max-width: 767px) {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        @media (max-width: 480px) {
          columns: 1;
          -webkit-columns: 1;
          -moz-columns: 1;
        }
      }
      > li {
        margin-bottom: 10px;
        a {
          color: $blue-font_color;
          position: relative;
          white-space: normal;
          &:hover {
            &::after {
              background: #f4a619;
              content: "";
              position: absolute;
              height: 2px;
              left: 0;
              bottom: 0px;
              width: 100%;
            }
          }
        }
        .clstoggle_menus {
          margin-left: 25px;
          margin-top: 10px;
          > ul {
            > li {
              margin-bottom: 10px;
              a {
                color: $yellow_color;
                &:hover {
                  color: $blue-font_color;
                }
              }
            }
          }
        }
      }
    }
  }
}
/*Inventory page styles end*/
/*Medication Tests page styles*/
.clsshop_container_blue{
  background: #f2f3f5 !important;
}
.clsmedication-tests_component{
  background: #f2f3f5;
}
.clsmedication-tests_component h1 {
  color: #02416e;
  font-size: 44px;
  font-family: poppinsbold;
  text-align: center;
  padding-top: 70px;
}
.clsmedication-tests_component p.medtest-head{
  font-size: 22px;
  margin:40px 0px;
  line-height:35px
}
.medtest-addedtocart{
  padding-bottom: 80px;
  text-align: center;
  max-width: 450px;
  margin: 0px auto;
  font-size: 18px;
  display: none;
}
.withThankYouMessage .medtest-addedtocart{
  display: block;
}
.medtest-addedtocart h3{
  color: #02416e;
  font-size: 24px;
  padding: 20px 0px;
}
.medtest-head{
  letter-spacing: 0.5px;
}
.medtest-steps {
  padding-bottom: 30px;
  font-size: 20px;
  color: #4a4a4a;
}
.medtest-steps .mdsd{
  padding-right:30px;
}

.clsmedication-tests_component p.medtest-bottom{
  font-size: 14px;
  color:#666;
  margin:25px 0px;
}
.clsmedication-tests_component p.medtest-req{
  font-size: 18px;
  color:$grey-other-font-color;
  line-height: 25px;
  margin:0px 0px 50px;
}
.clsmedication-tests_component .bull{
  padding-right:25px;
}
.medtest-head-intro{
  background: url("/assets/images/medicationtesting.png") no-repeat bottom right;
  padding-bottom: 80px;
}
.medtest-head-intro-top{
  max-width: 890px;
}
.medtest-head-intro-important{
  max-width: 780px;
}
.medtest-box-wrapper{
  position: relative;
  max-width: 935px;
  margin: 30px auto 15px;
}
.medtest-box{
  border: 1px solid #f2f3f5;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 #bccfdd;
  -webkit-box-shadow: 0 1px 3px 0 #bccfdd;
  padding: 35px 35px 8px 35px;

}

.medtest-box-head{
  box-shadow: 0 1px 1px #d8d8d8, inset 0 0 0 #d8d8d8;
  border-radius: 5px;
  background-color: #d8d8d8;
  color: #0b426a;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 17px 7px;
  margin-bottom:27px;
}
.medtest-box-head-text{
  margin-right:13px;
  background: url("/assets/images/medTestHeader-closed.png") no-repeat center right;
  cursor: pointer;
}
.medtest-box-prod{
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25), inset 0 0 0 rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 15px 15px;
  margin-bottom:27px;
}
.medtest-select-button{
  color: #0b426a;
  font-size: 16px;
  background:#dbdfe3;
  border-radius: 5px;
  font-family: $poppin_semibold;
  cursor:pointer;
  width:113px;
  text-align:center;
  padding: 13px 0px 12px;

}

.medtest-select-button:hover{
  background:#f3a61d;
  color:#fff;
}
.medtest-select-button-disabled, .medtest-select-button-disabled:hover{
  color: #fff;
  background:#dbdfe3;
}
.medtest-selected{
  background:#f3a61d;
  color:#fff;
}
.medtest-mb-0{
  margin-bottom:0px;
}
.medtest-box-prod-details{
  float:left;
  width:690px;
}
.medtest-box-prod-selection{
  float:right;
  padding: 15px 10px 0px 0px;
}
.medtest-box-clearfix {
  content: "";
  clear: both;
}
.medtest-desc{
  color: #788995;
  font-size: 16px;
}
.medtest-name{
  font-size: 22px;
  color: #0b426a;
  font-weight:700;
  float: left;
}
.medtest-price{
  float: right;
  font-size: 12px;
  color: #788995;
  line-height: 31px;
}
.medtest-price-text{
  color: #788995;
  font-size: 13px;
  line-height: 40px;
  padding-right:20px;
}
.medtest-price-currency{
  color: #f3a61d;
  line-height: 40px;
  font-size: 13px;
}

.medtest-addtocart-button{
  padding: 35px 35px 20px;
}
.btn_medtestadd{
  font-family: poppinsregular;
  font-weight: 500;
  border-radius: 5px;
  color:#fff;
  display:block;
  border-radius: 8px;
  font-size: 16px;
  text-align:center;
  padding: 13px 0px;
  width: 100%;
  background: #f5c775;
  border-color: #f5c775;
}
.btn_medtestadd:hover, .btn_medtestadd:active, .btn_medtestadd:focus , .btn_medtestadd:active:hover{
  background: #f3a61d;
  border-color: #f3a61d;
  color:#fff;
}

.btn_medtestadd_active{
  background: #f3a61d;
  border-color: #f3a61d;
}
.btn_medtestadd_active:hover, .btn_medtestadd_active:active, .btn_medtestadd_active:focus, .btn_medtestadd_active:active:hover{
  border-color: #dd8e00;
  background-color: #dd8e00;
}


.med-price-variant{
  float: right;
  height: 40px;
  position: relative;
}
.med-price-variant-display{
  line-height: 40px;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.25), inset 0 0 0 rgba(0, 0, 0, 0.25);
  width: 330px;
  border-radius: 3px;
  background: #fff;
}
.med-price-variant-info{
  color: #0b426a;
  font-family: $proxima_regular;
  background: url("/assets/images/arowMedTest-close.png") no-repeat center right;
  font-weight: normal;
  margin-right:35px;
  padding-left: 25px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
.med-price-variant-open .med-price-variant-info{
    background: url("/assets/images/arowMedTest.png") no-repeat center right;
}
.med-price-variant-box{
  display: none;
}
.med-price-variant-open .med-price-variant-box{
  display: block;
}
.med-price-variant-box-prod {
  background: #fff;
  font-size: 16px;
  color: #f3a61d;
  border-top: 1px solid #e4eaee;
  cursor: pointer;
  padding: 5px 30px 5px 25px;
}
.toorg{
  color: #f3a61d;
}
.med-price-variant-qty{
  padding-right: 50px;
  width: 110px;
  color: $grey-title-font-color;
  display: inline-block;
}
.med-price-variant-selection{
  float: right;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  width: 25px;
  height: 25px;
  margin-top: 8px;
  background-color: #fff;
  background-image: none;
}
.med-price-variant-selected{
  background: url("/assets/images/medTestselected.png") no-repeat center;
}

.medtest-step .medicationtests-content{
  display: none;
}
.medtest-step-open .medicationtests-content{
  display:block;
}
.medtest-step-open .medtest-box-head-text{
  background: url("/assets/images/medTestHeader.png") no-repeat center right;
}
.medication-test-form .medtest-radio label.inputlab{
  color: #666666;
  font-size:14px;
  padding-left: 7px;
  padding-right: 20px;
  position: relative;
  top: -2px;
}
.medication-test-form label{
  color: $grey-other-font-color;
  font-size: 18px;
  font-weight: normal;

}
.medtest-radio{
  padding-top: 3px;
}
@media (max-width: 1200px){
  .medtest-head-intro {
      background-image: none;
  }
  .clsmedication-tests_component p.medtest-head{
    max-width: none;
  }
}
@media (max-width: 992px){
  .medtest-head-intro {
      background-image: none;
  }
  .clsmedication-tests_component p.medtest-head{
    max-width: none;
  }
}
@media (max-width: 992px){
  .medtest-steps{
    text-align: center;
  }
  .medtest-name, .medtest-price{
    float: none;
  }
  .medtest-box-prod-details{
    float: none;
    width: auto;
  }
  .med-price-variant-display{
    width: auto;
  }
  .medtest-box-prod-selection{
    float: none;
  }
  .med-price-variant{
    float: none;
    height: auto;
    margin: 15px 0px;
  }
  
}

/*Medication Tests page styles and*/
/*Cart page styles*/
.clscart_section {
  padding: 40px 0 0;
  .clscart_blk {
    form {
      .clsuser_help_blk {
        clear: both;
        overflow: hidden;
        h1 {
          @include h1_style();
          float: left;
          @media (max-width: 767px) {
            float: none;
          }
          span {
            font-size: 22px;
            color: $grey-title-font-color;
            font-family: $proxima_semibold;
            position: relative;
            left: 10px;
            top: -7px;
            @media (max-width: 414px) {
              position: static;
              display: block;
            }
          }
        }
        .clscart_checkout_top_btn {
          float: right;
          @media (max-width: 767px) {
            float: none;
            margin-top: 20px;
          }
          .clsyellow_btn {
            @include yellowbutton();
            min-width: 270px;
          }
        }
      }
      .clscart_table {
        @include whitebox();
        padding: 30px 0;
        margin-top: 20px;
        .table-responsive {
          @media (max-width: 767px) {
            border: 0 none;
          }
          table {
            thead {
              th {
                text-transform: uppercase;
                font-family: $proxima_bold;
                font-weight: normal;
                color: $grey-title-font-color;
                border: 0 none;
                padding: 10px 30px;
                padding-bottom: 25px;
                vertical-align: middle;
                text-align: center;
                .clsacc_profile_top_btn {
                  @include yellowbutton();
                  float: right;
                }
                .clsacc_profile_top_btn_grey {
                  @include greybutton();
                }
                &:first-child {
                  padding-left: 30px;
                  text-align: left;
                }
                &:last-child {
                  text-align: right;
                  padding-right: 30px;
                }
              }
            }
            tbody {
              color: $primary-font-color;
              tr {
                box-shadow: 0 3px 4px 0 rgba(222, 222, 222, 0.5);
                -moz-box-shadow: 0 3px 4px 0 rgba(222, 222, 222, 0.5);
                -webkit-box-shadow: 0 3px 4px 0 rgba(222, 222, 222, 0.5);
                
                td {
                  vertical-align: middle;
                  border: 0 none;
                  padding: 10px 30px;
                  text-align: center;
                  @media (max-width: 767px) {
                    padding: 10px;
                  }
                  &:first-child {
                    padding-left: 30px;
                    text-align: left;
                  }
                  &:last-child {
                    text-align: right;
                    padding-right: 30px;
                  }
                  .media {
                    .media-body {
                      .media-heading {
                        a {
                          color: $primary-font-color;
                          &:hover,
                          &:focus {
                            color: $yellow_color;
                          }
                        }
                      }
                    }
                  }
                  .clsquantity {
                    width: 150px;
                    span {
                      display: table-cell;
                      vertical-align: middle;
                      &.clsqnty_addminus {
                        background: url("/assets/images/add_minus_sprit_icon.png") no-repeat scroll 0 0;
                        width: 22px;
                        cursor: pointer;
                      }
                      &.clsicon_minus {
                        background-position: 0px center;
                        @media (max-width: 1199px) {
                          background-position: 0px center;
                        }
                      }
                      &.clsicon_add {
                        background-position: -83px center;
                      }
                      &.blueminues {
                        background-position: -46px center;
                        @media (max-width: 1199px) {
                          background-position: -46px center;
                        }
                      }
                      .form-control {
                        @include form_control_style();
                        background: none;
                        min-width: 75px;
                        text-align: center;
                        color: $primary-font-color;
                      }
                    }
                  }
                }
                &:last-child {
                  box-shadow: none;
                  -moz-box-shadow: none;
                  -webkit-box-shadow: none;
                  td{
                    padding-top: 20px;
                  }
                }
              }
            }
          }
        }

        .clscart_bottom_empty {
          padding: 0px 30px;
        }

      }
      .clscart_bottom_checkout {
        padding: 20px 0 40px;
        clear: both;
        overflow: hidden;
        .clsaccount_top_backto_btn {
          float: left;
          @media (max-width: 767px) {
            float: none;
          }
          a .glyphicon {
            font-size: 12px;
            margin-right: 2px;
          }
        }
        .clscart_checkout_top_btn {
          float: right;
          @media (max-width: 767px) {
            float: none;
            margin-top: 20px;
          }
          .clsyellow_btn {
            @include yellowbutton();
            min-width: 270px;
          }
        }
      }
    }
    .clsorder_item_list_blk {
      background: #ffffff;
      padding-top: 50px;
      h3 {
        font-size: 33px;
        font-family: $poppin_semibold;
        color: #02416e;
        margin-bottom: 30px;
        
      }
      .col-md-3.col-sm-4.col-xs-6 {
        @media (max-width: 567px) {
          width: 100%;
        }
        .clsorder_item_list_box {
          margin-bottom: 30px;
          figure {
            text-align: center;
            img {
              display: inline-block;
            }
          }
          h5 {
            font-family: $poppin_semibold;
            text-align: center;
            margin-top: 18px;
            a {
              color: #33333a;
            }
          }
        }
        &:last-child .clsorder_item_list_box {
          margin-bottom: 0px;
        }
      }
      
    }
  }
}

/*Cart page styles end*/

.clsmax_width1400 {
  max-width: 1400px;
  margin: 0 auto;
}

.clssmall_p15 {
  font-size: 15px;
  color: #85949f;
  padding: 10px 0;
}

.clscalculate_blk {
  border-bottom: 2px solid #e4eaee;
  padding-bottom: 10px;
  ul {
    li {
      padding: 17px 0;
      font-size: 18px;
      color: #1b2125;
      border: 0 none !important;
      padding: 5px 0 !important;
      span {
        float: right;
      }
    }
  }
}

.clsshop_container {
  background: #fff;
  .clshop_section {
    margin-top: 10px;
    .clsshop_filters_blk {
      padding-right: 30px;
      @media (max-width: 1199px) {
        padding-right: 0px;
      }
      .clsfilter_title {
        box-shadow: 0 2px 0 0 #f3f3f3;
        padding: 8px 0;
        span {
          font-size: 12px;
          float: right;
          a {
            color: $grey-title-font-color;
            &:hover {
              color: $primary-font-color;
            }
          }
        }
      }
      #accordion.panel-group {
        margin-bottom: 10px;
        letter-spacing: 1px;
        > .panel {
          -webkit-box-shadow: none;
          box-shadow: none;
          border-bottom: 0 none;
          border-left: 0 none;
          border-right: 0 none;
          .panel-heading {
            background: none;
            padding-left: 0px;
            padding-top: 20px;
            font-family: $poppin_semibold;
            .panel-title {
              a {
                display: block;
                i.glyphicon {
                  float: right;
                  font-size: 11px;
                }
              }
            }
          }
          .panel-collapse {
            .panel-body {
              padding: 10px;
              border: 0 none;
              .panel-group {
                margin-bottom: 10px;
                .panel {
                  border: 0 none;
                  -webkit-box-shadow: none;
                  box-shadow: none;
                  .panel-heading {
                    padding: 8px 15px 8px 0px;
                    font-family: $proxima_regular;
                    .panel-title {
                      a {
                        color: $yellow_color;
                        i.glyphicon {
                          color: #1b2125;
                          float: left;
                          margin-right: 10px;
                        }
                        // &[data-toggle="collapse"]{
                        //     color: $primary-font-color;
                        // }
                        &.collapsed {
                          color: $primary-font-color;
                        }
                      }
                    }
                  }
                  .panel-collapse {
                    .panel-body {
                      padding-top: 0;
                      padding-bottom: 0;
                      border: 0 none;
                      ul {
                        padding: 0 0 0 25px;
                        li {
                          padding-bottom: 15px;
                          a {
                            color: #666666;
                          }
                          &:last-child {
                            padding-bottom: 0px;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .clsfeature_list {
                li {
                  .checkbox {
                    margin-bottom: 15px;
                  }
                }
              }
            }
          }
        }
        .clscheckbox_panel {
          .panel-body {
            padding-left: 0px !important;
            .clsfeature_list {
              li {
                // padding: 2px 0;
              }
            }
          }
        }
      }
    }
    .clsshop_products_blk {
      @media (max-width: 767px) {
        margin-top: 25px;
      }
      .clspagination_blk {
        .clsproduct_title {
          font-family: $poppin_semibold;
          display: inline-block;
          vertical-align: middle;
          width: 29%;
          @media (max-width: 767px) {
            display: block;
            width: 100%;
          }
        }
        .clspagination_div {
          display: inline-block;
          vertical-align: middle;
          width: 70.5%;
          @media (max-width: 1199px) {
            width: 70%;
            @media (max-width: 767px) {
              display: block;
              width: 100%;
            }
          }
          nav {
            text-align: right;
            @media (max-width: 767px) {
              text-align: left;
              margin-top: 10px;
            }
            p {
              display: inline-block;
              vertical-align: middle;
              font-family: $poppin_semibold;
              span {
                font-family: $poppin_regular;
                padding: 0 5px;
                font-size: 14px;
                a {
                  color: $primary-font-color;
                  font-family: $proxima_regular;
                  &.active {
                    color: $yellow_color;
                  }
                }
              }
            }
            .pagination {
              display: inline-block;
              vertical-align: middle;
              margin: 0px;
              li {
                &:first-child {
                  a {
                    border-left: 1px solid #dedede;
                  }
                }
                &:last-child {
                  a {
                    border-right: 1px solid #dedede;
                  }
                }
                a {
                  color: $primary-font-color;
                  border-left: 0 none;
                  border-right: 0 none;
                  span.glyphicon {
                    color: $yellow_color;
                    font-size: 11px;
                  }
                }
                > span.dots {
                  border-right: 1px solid #dedede;
                  position: relative;
                  float: left;
                  padding: 6px 12px;
                  line-height: 1.42857143;
                  z-index: 2;
                  &:hover {
                    background-color: transparent;
                  }
                }
                &.active {
                  a {
                    color: $yellow_color;
                    background: none;
                    border-top: 1px solid #dedede;
                    border-bottom: 1px solid #dedede;
                  }
                }
                &.disabled {
                  a {
                    cursor: default !important;
                    color: #dedede;
                    span.glyphicon {
                      color: #dedede;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .clspagination_blk_bottom {
        .clspagination_div {
          width: 100%;
        }
      }
      .clsproducts {
        margin-top: 25px;
        .row {
          .col-lg-3.col-md-4.col-sm-6.col-xs-6 {
            padding-left: 5px;
            padding-right: 5px;
            @media (max-width: 567px) {
              width: 100%;
            }
          }
        }
        .clsproduct_box {
          margin-bottom: 30px;
          padding: 10px;
          max-width: 235px;
          @media (max-width: 767px) {
            max-width: 235px;
            margin: 0 auto 30px;
          }
          &:hover {
            box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075),
            0 0 2px rgba(0, 0, 0, 0.5);
            -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075),
            0 0 2px rgba(0, 0, 0, 0.5);
            border-radius: 8px;
          }
          figure.clsproduct_img {
            border-radius: 10px;
            text-align: center;
            a {
              display: inline-block;
            }
          }
          h4 {
            font-family: $poppin_bold;
            font-size: 14px;
            padding: 10px 0;
            line-height: 22px;
            a {
              color: $primary-font-color;
              &:hover {
                color: $yellow_color;
              }
            }
          }
          p.clsproduct_price {
            font-family: $poppin_regular;
            font-size: 14px;
          }
        }
      }
    }
  }
  .clsproduct_details_section {
    .row {
      .col-md-6.col-sm-6 {
        position: static;
        #zoom {
          position: absolute;
          width: 100px;
          height: 100px;
          background-repeat: no-repeat;
          border-radius: 50px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
          display: none;
        }
        #wrapper {
          #content {
            #thumbs {
              position: relative;
              margin: 20px 15px 20px 0px;
              float: left;
              width: 20%;
              max-width: 100px;
              vertical-align: top;
              @media (max-width: 991px) {
                width: 100%;
                max-width: 100%;
              }
              #pics-thumbs {
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                transition-duration: 0.3s;
                ul {
                  margin: 2px 0 0 2px;
                  @media (max-width: 991px) {
                    text-align: center;
                  }
                  li {
                    padding: 0px 0px 15px;
                    width: 99%;
                    @media (max-width: 991px) {
                      width: auto;
                      display: inline-block;
                    }
                    img {
                      border-radius: 4px;
                      cursor: pointer;
                      height: 75px;
                      transition-duration: 0.3s;
                      padding: 4px;
                      box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
                    }
                  }
                }
              }
            }
            #view {
              margin: 20px 0;
              height: 450px !important;
              float: right;
              width: 82%;
              vertical-align: top;
              @media (max-width: 1399px) {
                width: 79%;
                @media (max-width: 1199px) {
                  width: 76%;
                  @media (max-width: 991px) {
                    width: 100%;
                  }
                }
              }
              img {
                border-radius: 8px;
              }
            }
          }
        }
        .clsproduct_slider_blk {
          //margin-top: 20px;
        }
      }
    }
    .clsproduct_details_blk {
      margin-top: 20px;
      margin-bottom: 30px;
      h2 {
        @include h2_style();
        margin-bottom: 20px;
      }
      h4 {
        font-size: 26px;
        color: $yellow_color;
        font-family: $proxima_regular;
        margin-bottom: 25px;
        span {
          font-size: 14px;
          color: $grey-title-font-color;
        }
      }
      .clsunstyled {
        li {
          display: inline-block;
          padding: 0 2px;
          .styled-checkbox {
            position: absolute; // take it out of document flow
            opacity: 0; // hide it

            & + label {
              position: relative;
              cursor: pointer;
              padding: 10px 15px;
              border: 1.5px solid rgb(230, 229, 229);
              font-size: 14px;
              font-family: $proxima_regular;
              font-weight: normal;
              color: $grey-title-font-color;
              border-radius: 4px;
              &:before {
                content: "";
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                background: white;
                position: absolute;
              }
            }
            &:checked + label {
              border: 1.5px solid $yellow_color;
              color: #33333a;
            }
          }
        }
      }
      .clsadd_cart_btn_blk {
        display: table;
        width: 100%;
        margin-top: 25px;
        .clsyellow_btn {
          @include yellowbutton();
          min-width: 248px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;
        }
        .clsgrey_btn {
          @include greybutton();
        }
        span {
          display: inline-block;
          vertical-align: middle;
          font-size: 18px;
          a {
            color: $yellow_color;
            &:hover {
              color: $blue-font_color;
            }
          }
        }
      }
      .clsproduct_desciption {
        margin-top: 35px;
        padding-top: 35px;
        box-shadow: 0 -2px 0 0 #f9f8f6;
        -webkit-box-shadow: 0 -2px 0 0 #f9f8f6;
        h3 {
          font-size: 22px;
          position: relative;
          display: inline-block;
          padding: 0 4px;
          margin-bottom: 45px;
          color: $blue-font_color;
          &::after {
            background: #f4a619;
            content: "";
            height: 3px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -10px;
          }
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
}

/* Home user styles*/
.clsuser_home_container {
  background-color: #fff;
  .clsuser_help_blk {
    h1 {
      @include h1_style();
      text-align: center;
      font-size: 34px;
    }
    ul {
      max-width: 800px;
      margin: 35px auto;
      text-align: center;
      li {
        display: inline-block;
        vertical-align: top;
        width: 33%;
        max-width: 197px;
        .clsuser_help_box {
          background: #fff;
          border: 1px solid #eee;
          border-radius: 4px;
          position: relative;
          text-align: center;
          margin: 0 16px 15px;

          a {
            display: block;
            figure {
              max-width: 100%;
              img {
                max-width: 100%;
              }
            }
            h5 {
              font-family: $poppin_bold;
              font-size: 14px;
              color: #0b426a;
              position: absolute;
              left: 0px;
              width: 100%;
              bottom: 35px;
              margin: 0 auto;
              @media (max-width: 767px) {
              }
              @media (max-width: 576px) {
                font-family: $poppin_bold;
                bottom: 20px;
                font-size: 12px;
              }
              
            }
          }
          &:hover {
            border: 1px solid $yellow_color;
            -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075),
            0 0 2px rgba(244, 166, 25, 0.7);
            -moz-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075),
            0 0 2px rgba(244, 166, 25, 0.7);
          }
          @media (max-width: 600px) {
            margin: 0px 7px 15px ;            
          }
        }
        
      }
    }


    .clsuser_attention_blk {
      border: 1px solid #F2F3F5; border-radius: 8px; background-color: #FFFFFF; box-shadow: 0 1px 3px 0 #BCCFDD; -webkit-box-shadow: 0 1px 3px 0 #BCCFDD; padding: 35px 50px; margin-top: 25px;
      @media (max-width: 767px) {
        padding: 25px;
      }

      h4 {
        color: #02416E; font-size: 44px; font-family: poppinsbold; font-size: 22px; margin-bottom: 20px;
        @media (max-width: 767px) { font-size: 38px; }
        @media (max-width: 767px) { font-size: 22px; }
      }

      .clsattention_content_blk {
        width: 100%; display: table;
        .clsattention_content {
          display: table-cell; width: 65%; vertical-align: top;
          @media (max-width: 767px) { display: block; width: 100%; }

          p {
            font-family: proxima_novaregular; font-size: 18px; letter-spacing: 1px;
            span { color: #F4A619; }
          }
        }

        .clsattention_btn {
          display: table-cell; float: right; vertical-align: top;
          @media (max-width: 767px) {  display: block; width: 100%; margin-top: 20px; }
          .clsyellow_btn {
            height: 50px; border-radius: 8px; background-color: #F4A619; border-color: #F4A619; color: #fff; padding: 15px 25px; font-family: poppinsregular; font-weight: 500; font-size: 16px; width: 240px;
            &:hover, &:focus {
              border-color: #dd8e00; background-color: #dd8e00;
            }
          }
        }

      }

    }

  }
  .clsuser_home_recent_purchases{
    padding-bottom: 50px;
    
    .clsuser_home_recent_purchases_tab{
      @include whitebox();
      position: relative;
      max-width: 810px;
      margin: 0px auto;
      .clsuser_home_recent_purchases_title{
        font-family: $poppin_semibold;
        font-size: 30px;
        color: #02416E;
        text-align: center;
        padding: 10px 12px;
        box-shadow: 0 2px 4px 0 rgba(222, 222, 222, 0.5);
        @media (max-width: 767px) {
          font-family: $poppin_regular;
          font-size: 21px;
        }
      }
      .clsview_allorders {
        position: absolute;
        top: 6px;
        right: 30px;
        
        a{
          font-size: 16px;
          font-family : $poppin_regular;
          color: $yellow_color;
          &:hover {
            color: $primary-font-color;
          }
        }
        @media (max-width: 767px) {
          position: static;
          float: right;
        }
      }
      .table-fo {
        table {
          margin-bottom: 0px;
          thead {
            font-size: 18px;
            color: $grey-title-font-color;
            text-transform: uppercase;
            letter-spacing: 0.41px;
            font-family: $proxima_semibold;
            th {
              font-weight: normal;
              border: 0 none;
            }
          }
          tbody {
            tr {
              box-shadow: 0 2px 4px 0 rgba(222, 222, 222, 0.5);
              &:last-child {
                box-shadow: none;
              }
              td {
                vertical-align: middle;
                border: 0 none;
                padding: 12px;
                .media {
                  display: table;
                  .media-left {
                    padding-right: 30px;
                    padding-left: 20px;
                  }
                  .media-body {
                    h4 {
                      
                      font-family: $proxima_bold;
                      a {
                        color: $blue-font_color;
                        font-size: 22px;
                        &:hover {
                          color: $yellow_color;
                        }
                        @media (max-width: 767px) {
                          font-size: 15px;
                        }
                      }
                    }
                  }
                }
                a.clsreorder{
                  font-size: 16px;
                  font-family : $poppin_regular;
                  color: $yellow_color;
                  padding-right: 18px;
                  &:hover {
                    color: $primary-font-color;
                  }
                  @media (max-width: 767px) {
                    padding-right: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .clsuser_home_tab_blk {
    
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(
                    top,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 20%,
                    rgba(235, 237, 242, 1) 20%,
                    rgba(235, 237, 242, 1) 80%,
                    rgba(255, 255, 255, 1) 80%,
                    rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-gradient(
                    left top,
                    left bottom,
                    color-stop(0%, rgba(255, 255, 255, 1)),
                    color-stop(20%, rgba(255, 255, 255, 1)),
                    color-stop(20%, rgba(235, 237, 242, 1)),
                    color-stop(80%, rgba(235, 237, 242, 1)),
                    color-stop(80%, rgba(255, 255, 255, 1)),
                    color-stop(100%, rgba(255, 255, 255, 1))
    );
    background: -webkit-linear-gradient(
                    top,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 20%,
                    rgba(235, 237, 242, 1) 20%,
                    rgba(235, 237, 242, 1) 80%,
                    rgba(255, 255, 255, 1) 80%,
                    rgba(255, 255, 255, 1) 100%
    );
    background: -o-linear-gradient(
                    top,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 20%,
                    rgba(235, 237, 242, 1) 20%,
                    rgba(235, 237, 242, 1) 80%,
                    rgba(255, 255, 255, 1) 80%,
                    rgba(255, 255, 255, 1) 100%
    );
    background: -ms-linear-gradient(
                    top,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 20%,
                    rgba(235, 237, 242, 1) 20%,
                    rgba(235, 237, 242, 1) 80%,
                    rgba(255, 255, 255, 1) 80%,
                    rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 20%,
                    rgba(235, 237, 242, 1) 20%,
                    rgba(235, 237, 242, 1) 80%,
                    rgba(255, 255, 255, 1) 80%,
                    rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
    .clsuser_home_tab {
      @include whitebox();
      padding: 25px 25px;
      position: relative;
      .nav-tabs {
        font-family: $proxima_regular;
        font-size: 22px;
        border: 0 none;
        padding: 0 20px;
        @media (max-width: 767px) {
          padding: 0px;
          font-size: 20px;
        }
        li {
          padding: 0 10px;
          a {
            color: $grey-title-font-color;
            background: none;
            border: 0 none;
            padding: 10px 5px;
          }
          &.active {
            a {
              background: none;
              border: 0 none;
              border-bottom: 3px solid #f4a619;
              position: relative;
              color: #0b426a;
            }
          }
        }
      }
      .tab-content {
        .tab-pane {
          form {
            padding-top: 30px;
            .form-group {
              margin-bottom: 20px;
              label {
                @include label_style();
              }
              .form-control {
                @include form_control_style();
              }
              .clslist_sort {
                @include clslist_sort_style();
              }
            }
            .clsyellow_btn {
              @include yellowbutton();
              margin-top: 20px;
              font-size: 16px;
            }
            .clsforget_text {
              padding-top: 25px;
              a {
                font-size: 18px;
                color: $yellow_color;
                &:hover {
                  color: $primary-font-color;
                }
              }
            }
          }
          .clsview_allorders {
            font-size: 18px;
            position: absolute;
            top: 40px;
            right: 35px;
            @media (max-width: 767px) {
              position: static;
              margin: 20px 0;
              float: right;
            }
          }
          .table-responsive {
            margin-top: 20px;
            table {
              thead {
                font-size: 18px;
                color: $grey-title-font-color;
                text-transform: uppercase;
                letter-spacing: 0.41px;
                font-family: $proxima_semibold;
                th {
                  font-weight: normal;
                  border: 0 none;
                }
              }
              tbody {
                tr {
                  box-shadow: 0 2px 4px 0 rgba(222, 222, 222, 0.5);
                  &:last-child {
                    box-shadow: none;
                  }
                  td {
                    vertical-align: middle;
                    border: 0 none;
                    padding: 12px;
                    .media {
                      display: table;
                      .media-left {
                        padding-right: 20px;
                      }
                      .media-body {
                        h4 {
                          font-size: 16px;
                          a {
                            color: $primary-font-color;
                            &:hover {
                              color: $yellow_color;
                            }
                          }
                        }
                      }
                    }
                    .clsorder_Status {
                      border-radius: 18px;
                      background-color: #b4c3da;
                      font-family: $proxima_semibold;
                      font-size: 14px;
                      color: $primary-font-color;
                      text-transform: uppercase;
                      font-weight: normal;
                      padding: 5px 20px;
                      letter-spacing: 0.41px;
                      width: 100%;
                      max-width: 200px;
                      //text-overflow: ellipsis;
                      //white-space: nowrap;
                      //overflow: hidden;
                      text-align: center;
                      &.clsdelivered {
                        background-color: #f3a61d;
                      }
                    }
                    .clsorder_total {
                      font-family: $proxima_semibold;
                      font-size: 18px;
                      color: $primary-font-color;
                    }
                  }
                }
              }
            }
          }
          .clsno_order {
            padding: 40px 30px 30px;
            .clsno_order_btn_blk {
              margin-top: 35px;
              .clsyellow_btn {
                @include yellowbutton();
                min-width: 190px;
                &:last-child {
                  margin-left: 20px;
                }
                @media (max-width: 567px) {
                  display: block;
                  width: 100%;
                  &:last-child {
                    margin-left: 0px;
                    margin-top: 10px;
                  }
                }
              }
            }
          }

        }
      }
    }
  }
  .clsorder_item_list_blk {
    h3 {
      font-size: 33px;
      font-family: $poppin_semibold;
      color: #02416e;
      margin-bottom: 0px;
      @media (max-width: 767px) {
        font-family: $poppin_regular;
        font-size: 27px;
      }
    }
    .col-md-3.col-sm-4.col-xs-6 {
      @media (max-width: 567px) {
        width: 100%;
      }
      .clsorder_item_list_box {
        margin-bottom: 30px;
        figure {
          text-align: center;
          img {
            display: inline-block;
          }
        }
        h5 {
          font-family: $poppin_semibold;
          text-align: center;
          margin-top: 18px;
          a {
            color: #33333a;
          }
        }
        &:hover {
          figure {
            -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075),
            0 0 2px rgba(0, 0, 0, 0.7);
            -moz-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075),
            0 0 2px rgba(0, 0, 0, 0.7);
          }
          h5 {
            a {
              color: $yellow_color;
            }
          }
        }
      }
      &:last-child .clsorder_item_list_box {
        margin-bottom: 0px;
      }
    }
    
  }
}

.clswrapper .clsbefore_log_header {
  font-family: poppinsregular;
}

.clswrapper .clsbefore_log_header .navbar-default {
  background: none;
  border: 0 none;
  padding: 15px 0;
  font-size: 18px;
  margin-bottom: 0px;
}

@media (max-width: 991px) {
  .clswrapper .clsbefore_log_header .navbar-default {
    font-size: 16px;
  }
}

@media (max-width: 991px) and (max-width: 767px) {
  .clswrapper .clsbefore_log_header .navbar-default {
    padding: 15px 0;
  }
}

.clswrapper .clsbefore_log_header .navbar-default .navbar-header .navbar-toggle {
  border: 0 none;
  margin-top: 0px;
}

.clswrapper .clsbefore_log_header .navbar-default .navbar-header .navbar-toggle:focus, .clswrapper .clsbefore_log_header .navbar-default .navbar-header .navbar-toggle:hover {
  background: none;
}

.clswrapper .clsbefore_log_header .navbar-default .navbar-header .navbar-toggle .icon-bar {
  background-color: #0B426A;
}

.clswrapper .clsbefore_log_header .navbar-default .navbar-header .navbar-brand {
  padding-top: 0px;
}

.clswrapper .clsbefore_log_header .navbar-default .navbar-collapse .nav.navbar-nav.navbar-right {
  margin-right: 45px;
}

@media (max-width: 1199px) {
  .clswrapper .clsbefore_log_header .navbar-default .navbar-collapse .nav.navbar-nav.navbar-right {
    margin-right: 0px;
  }
}

.clswrapper .clsbefore_log_header .navbar-default .navbar-collapse .nav.navbar-nav.navbar-right li a {
  color: #02416E;
}

@media (max-width: 991px) {
  .clswrapper .clsbefore_log_header .navbar-default .navbar-collapse .nav.navbar-nav.navbar-right li a {
    padding: 10px 6px;
  }
}

.clswrapper .clsbefore_log_header .navbar-default .navbar-collapse .nav.navbar-nav.navbar-right li a:hover {
  color: #F4A619;
}

.clswrapper .clsbefore_log_header .navbar-default .navbar-collapse .nav.navbar-nav.navbar-right li.active a {
  background: none;
}

.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: inset 0 0 0.5px 0 rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: inset 0 0 0.5px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0 0 0.5px 0 rgba(0, 0, 0, 0.25);
  padding: 2px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 34px;
  cursor: text;
  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto;
    max-width: inherit;
    color: #02416E;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
  .navbar-brand {
    padding-top: 0px;
  }
}

.navbar-collapse {
  .nav.navbar-nav.navbar-right {
    margin-right: 45px;
    @media (max-width: 1199px) {
      margin-right: 0px;
    }
    li {
      a {
        color: $blue-font_color;
        @media (max-width: 991px) {
          padding: 10px 6px;
        }
        &:hover {
          color: $yellow_color;
        }
      }
      &.active {
        a {
          background: none;
        }
      }
    }
  }
}

.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: inset 0 0 0.5px 0 rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: inset 0 0 0.5px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0 0 0.5px 0 rgba(0, 0, 0, 0.25);
  padding: 2px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 34px;
  cursor: text;
  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto;
    max-width: inherit;
    color: #02416e;
    &:focus {
      border: none;
      box-shadow: none;
    }
    .clsfooter_top {
      background: #0B426A;
      padding: 40px 0 30px;
      color: #fff;
      .container {
        .clsfooter_logo {
          img {
            max-width: 100%;
          }
          p {
            margin-top: 20px;
            line-height: 24px;
            a {
              color: #fff;
            }
          }
        }
        .clsfooter_menu_blk {
          @media (max-width: 767px) {
            margin-top: 25px;
          }
          h3 {
            font-size: 18px;
            margin-bottom: 20px;
            font-family: $poppin_regular;
            font-weight: 600;
          }
          ul {
            @media (max-width: 767px) {
              margin-bottom: 20px;
            }
            li {
              padding-bottom: 7px;
              a {
                color: #fff;
                &:hover {
                  color: #F4A619;
                }
              }
            }
          }
        }
      }
    }
  }
}

.clsmain_footer {
  font-size: 14px;
  @media (max-width: 767px) {
    text-align: center;
  }
  .clsfooter_top {
    background: #0b426a;
    padding: 40px 0 30px;
    color: #fff;
    .container {
      .clsfooter_logo {
        p {
          margin-top: 20px;
          line-height: 24px;
          a {
            color: #fff;
          }
        }
      }
      .clsfooter_menu_blk {
        @media (max-width: 767px) {
          margin-top: 25px;
        }
        h3 {
          font-size: 18px;
          margin-bottom: 20px;
          font-family: $poppin_regular;
          font-weight: 600;
        }
        ul {
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
          li {
            padding-bottom: 7px;
            a {
              color: #fff;
              &:hover {
                color: #f4a619;
              }
            }
          }
        }
      }
    }
  }
  .clscopyright {
    background: #ebedf2;
    padding: 20px 0;
  }
}

.clsvideo_modal {
  text-align: center;
  &:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 1360px;
    @media (max-width: 1399px) {
      width: 900px;
      @media (max-width: 991px) {
        width: 700px;
        @media (max-width: 767px) {
          width: 400px;
          margin: 0px;
          @media (max-width: 480px) {
            width: 280px;
          }
        }
      }
    }
    .modal-content {
      @media (max-width: 767px) {
        width: 100%;
        margin: 0px;
      }
      .modal-body {
        height: 700px;
        @media (max-width: 1399px) {
          height: 400px;
          @media (max-width: 767px) {
            height: 250px;
          }
        }
      }
    }
  }
  .close {
    color: #fff;
    opacity: 1;
    font-size: 50px;
    right: 15px;
    top: 10px;
    position: absolute;
  }
}

.clscheckout_footer {
  ul {
    @media (max-width: 568px) {
      text-align: center;
    }
    padding-bottom: 30px;
    li {
      display: inline-block;
      padding: 0 13px;
      @media (max-width: 568px) {
        padding-bottom: 5px;
      }
      a {
        color: #788995;
        &:hover {
          color: $yellow_color;
        }
      }
    }
  }
}

.clsaddress_delete_confirm_modal {
  text-align: center;
  padding-right: 0px !important;
  .vertical-alignment-helper {
    display: table;
    height: 100%;
    width: 100%;
  }
  .vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    @media (max-width: 767px) {
      width: 95%;
    }
    .modal-content {
      width: inherit;
      height: inherit;
      /* To center horizontally */
      margin: 0 auto;
      .modal-header {
        padding: 25px;
      }
      .modal-body {
        padding: 30px;
        a {
          font-size: 18px;
        }
        .clsyellow_btn {
          @include yellowbutton();
          margin-left: 40px;
          @media (max-width: 499px) {
            display: block;
            width: 100%;
            margin-top: 10px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}

/*Prescription form css*/
.prescription_faq {
  line-height: 25px;
  color: #4a4a4a;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 22px;
  padding: 25px 0px 50px !important;
  ul.clsprescription_details {
    line-height: 25px;
    li {
      margin: 0 0 19px;
      span {
        font-family: $proxima_semibold;
      }
      a {
        color: #4a4a4a;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  h5 {
    color: #f4a619;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2.23px;
    line-height: 22px;
  }
  h1 {
    padding: 7px 0 21px !important;
    line-height: 56px;
    @include h1_style();
  }
  .prescription_loc {
    color: #92a9bf;
    border: 1px solid #f2f3f5;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 #bccfdd;
    -webkit-box-shadow: 0 1px 3px 0 #bccfdd;
    padding: 17px 30px 34px;
    p {
      color: #666666;
      font-size: 18px;
      line-height: 29px;
      padding-top: 11px;
    }
    .pres_row {
      padding-bottom: 44px;
      border-bottom: 1px solid #e4eaee;
      margin-bottom: 42px;
      clear: both;
      display: block;
      &:last-child {
        border-bottom: 0px solid #e4eaee;
        margin-bottom: 0px;
        padding-bottom: 20px;
      }
      .drug_co {
        margin-bottom: 9px;
        ul {
          display: table-row;
          li {
            display: table-cell;
            height: 100%;
            list-style-type: none;
            margin: 0px 21px 0 0 !important;
            vertical-align: middle;
            h5 {
              color: #0b426a;
              text-transform: none;
              letter-spacing: 1px;
              padding-left: 11px;
              font-size: 22px;
              letter-spacing: 0.5px;
            }
          }
        }

        .clear_fix {
          line-height: 0px !important;
          font-size: 0px !important;
          display: block !important;
          clear: both !important;
        }
      }
    }
    p {
      span {
        font-family: $proxima_semibold;
      }
    }
  }
}

/*Prescription form css end*/
.valisure_select {
  .ui-dropdown {
    @include form_control_style();
    width: 100% !important;
    border-radius: 4px !important;
    height: 40px;
    padding: 0px;
    label {
      font-size: 18px !important;
      color: $grey-title-font-color !important;
      padding: 6px 17px;
    }
    .ui-dropdown-trigger {
      background: none;
      .ui-dropdown-trigger-icon {
        font-size: 14px;
        color: $yellow_color;
      }
    }
    .ui-dropdown-panel {
      .ui-dropdown-items-wrapper {
        .ui-dropdown-items {
          .ui-state-highlight {
            background-color: #eaeaea;
            color: #333;
          }
        }
      }
    }
  }
}

.show-password {
  position: relative;
  > a {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  > input {
    padding-right: 50px !important;
  }
}

.valisure_error {
  > div, .ng-star-inserted {
    background-color: #ff5757;
    border-radius: 0 0 2px 2px;
    color: #ffffff;
    font-size: 14px;
    padding-left: 6px;
    font-family: $proxima_regular;
    letter-spacing: 1.12px;
  }
}

.clsdate_picker {
  .ui-calendar {
    width: 100%;
    .ui-inputtext {
      @include form_control_style();
      width: 100%;
    }
  }
}

input {
  &.form-control {
    &.ng-pristine {
      &.ng-invalid {
        &.ng-touched {
          //border: 1px solid #ff5757 !important;
        }
      }
    }
  }
}

input {
  &.form-control {
    &.ng-touched {
      &.ng-dirty {
        &.ng-invalid {
          border: 1px solid #ff5757 !important;
        }
      }
    }
  }
}

#signin {
  form {
    .form-group {
      .alert-danger {
        background-color: #ff5757;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top-color: transparent;
        color: #ffffff;
        font-size: 14px;
        padding: 0 6px;
        font-family: $proxima_regular;
        letter-spacing: 1.12px;
      }
    }
  }
}

.form-group {
  .alert-danger {
    background-color: #ff5757;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top-color: transparent;
    color: #ffffff;
    font-size: 14px;
    padding: 0 6px;
    font-family: $proxima_regular;
    letter-spacing: 1.12px;
  }
}

.clsmedication {
  .ui-inputtext {
    @include form_control_style();
    width: 100%;
    &:hover,
    &:focus,
    &:active {
      border-color: #e5e5e5 !important;
    }
  }
}

.clschips {
  .ui-chips {
    line-height: 11px;
    ul {
      width: 100%;
      border-radius: 4px;
      border-color: #e5e5e5;
      &:hover,
      &:focus,
      &:active {
        border-color: #e5e5e5 !important;
      }
      li.ui-chips-input-token {
        input {
          @include form_control_style();
          width: 100%;
          box-shadow: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          height: 25px;
        }
      }
      li.ui-chips-token {
        background: #fff !important;
        border: 0.8px solid #d9d9d9;
        line-height: 21px;
        color: #5f6872 !important;
        .ui-chips-token-icon {
          color: #d9d9d9;
        }
      }
    }
  }
}

.empty-message {
  color: #92a9bf;
}

.clschage_pswd_modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        border-bottom: 0 none;
        padding-bottom: 0px;
        .close {
          font-size: 30px;
          color: $yellow_color;
          opacity: 1;
          position: absolute;
          top: 6px;
          right: 10px;
        }
      }
      .modal-body {
        padding-bottom: 60px;
        font-size: 18px;
      }
    }
  }
}

.clsmedication {
  &.ng-pristine {
    &.ng-invalid {
      &.ng-touched {
        input {
          // border: 1px solid #ff5757 !important;
        }
      }
    }
  }
}

.clschips {
  &.ng-pristine {
    &.ng-invalid {
      &.ng-touched {
        .ui-chips {
          ul {
            // border: 1px solid #ff5757 !important;
          }
        }
      }
    }
  }
}

.clsdate_picker {
  &.ng-tns-c4-0 {
    &.ng-pristine {
      &.ng-invalid {
        &.ng-touched {
          .ui-calendar {
            input {
              border: 1px solid #ff5757 !important;
            }
          }
        }
      }
    }
  }
}

/* Timeout modal popup styles*/
.clstimeout_modal {
  text-align: left;
  .modal-dialog {
    .modal-content {
      .modal-header {
        border-bottom: 0 none;
        padding-bottom: 0px;
        .close {
          font-size: 36px;
          opacity: 1;
        }
      }
      .modal-body {
        padding-top: 0px;
        h3 {
          text-transform: capitalize;
          margin-bottom: 20px;
        }
        p {
          padding-bottom: 10px;
        }
        .clsyellow_btn {
          margin-left: 0px;
          margin-top: 20px;
        }
      }
    }
  }
}
.clswrapper{
  .sign_in_form_blk {
    .tab-content {
      .tab-pane {
        form {
          .form-group {
            .ui-autocomplete-multiple-container.ui-inputtext {
              max-height: 150px !important;
              overflow-y: auto;
              height: auto !important;
              li.ui-autocomplete-token {
                margin-bottom: 6px !important;
                display: block !important;
                input {
                  font-family: $proxima_regular;
                  font-size: 18px;
                  &::-moz-placeholder {
                    color: $grey-title-font-color;
                  }
                  &:-ms-input-placeholder {
                    color: $grey-title-font-color;
                  }
                  &::-webkit-input-placeholder {
                    color: $grey-title-font-color;
                  }
                }
              }
            }
            label{
              color:#02416e;
            }
          }
          
        }
      }
    }
  }
}

/* Timeout modal popup styles*/
.clstimeout_modal {
  text-align: left;
  .modal-dialog {
    .modal-content {
      .modal-header {
        border-bottom: 0 none;
        padding-bottom: 0px;
        .close {
          font-size: 36px;
          opacity: 1;
        }
      }
      .modal-body {
        padding-top: 0px;
        h3 {
          text-transform: capitalize;
          margin-bottom: 20px;
        }
        p {
          padding-bottom: 10px;
        }
        .clsyellow_btn {
          margin-left: 0px;
          margin-top: 20px;
        }
      }
    }
  }
}

/* Multiple auto complete input tags style */
.sign_in_form_blk {
  .tab-content {
    .tab-pane {
      form {
        .form-group {
          .ui-autocomplete-multiple-container.ui-inputtext {
            max-height: 150px !important;
            overflow-y: auto;
            height: auto !important;
            li.ui-autocomplete-token {
              margin-bottom: 6px !important;
              display: block !important;
              input {
                font-family: $proxima_regular !important;
                font-size: 18px !important;
                &::-moz-placeholder {
                  color: $grey-title-font-color;
                }
                &:-ms-input-placeholder {
                  color: $grey-title-font-color;
                }
                &::-webkit-input-placeholder {
                  color: $grey-title-font-color;
                }
              }
            }
          }
        }
      }
    }
  }
}

.clsaddinsurance_button {
  margin-top: 20px;
  a {
    display: block;
    cursor: pointer;
    background-image: url('assets/images/addbtn@2x.png');
    background-size: 100% auto;
    width: 195px;
    height: 52px;
    background-repeat: no-repeat;
    span {
      display: block;
      padding-left: 50px;
      line-height: 50px;
      color: #005475;
      font-size: 14px;
      text-align: center;
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.btn_promocodeadd{
  font-family: poppinsregular;
  font-weight: 500;
  border-radius: 5px !important;
  color:#fff;
  border-radius: 8px;
  font-size: 16px;
  text-align:center;
  padding: 13px 25px;
  background: #f3a61d;
  border-color: #f3a61d;
}
.btn_promocodeadd:hover, .btn_promocodeadd:active, .btn_promocodeadd:focus , .btn_promocodeadd:active:hover{
  background: #f3a61d;
  border-color: #f3a61d;
  color:#fff;
}

.promocodeadd label{
  color: $grey-other-font-color;
  font-family: proxima_novaregular;
  font-weight: normal;
  letter-spacing: 1px;
}

h1.accountTitle{
  padding: 30px 0px;
  font-family: $poppin_bold;
  font-size: 44px;
  color: $blue-font_color;
  text-align: left;
  font-size: 44px;
  &.orderNumberTitle{
    font-size: 44px;  
  }
  @media (max-width: 767px) {
    font-size: 30px;
    text-align: center;
    padding:0px;
  }

}

.accountMessage{
  padding: 60px 0px;
  text-align: center;
}
.accountContainer{
  max-width: 810px;
  margin: 0px auto;
  margin-top: 0px;
  margin-bottom: 30px;
}
.accountBox{
  //border: 1px solid #f2f3f5;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 #bccfdd;
  -webkit-box-shadow: 0 1px 3px 0 #bccfdd;
  position: relative;
  margin-top: 20px;
}

.accountBoxTitle{
  font-family: $proxima_bold;
  font-size: 18px;
  color: $grey-title-font-color;
  padding: 25px 30px 20px;
  box-shadow: 0 2px 4px 0 rgba(222, 222, 222, 0.5);
  span{
    float: right;
  }
}

.accountBoxTitleSimple{
  font-size: 22px;
  color: #00406d;
  padding-bottom: 20px;
  font-family: $proxima_bold;
  border-bottom: 1px solid #e4eaee;
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.accountAddress{
  padding:0px 30px;
  &.accountAddressMapped{
    padding-bottom:30px;  
  }
  h4{
    border-bottom: 1px solid #E4EAEE;
    font-size: 18px;
    color: #1B2125;
    padding: 30px 0px 25px;
    margin-bottom: 25px;
    span{
      float: right;
      a{
        color: $yellow_color;
        margin: 0 0px 0px 20px;
        &:hover{
          color: $yellow_hover_color;
        }
        &.setdefault{
          @media (max-width: 500px) {
            display : none;
          }  
        }
        @media (max-width: 500px) {
          margin: 0 0px 0px 10px;
        }
      }
    }
  }
  .clscheckout_address{
    margin: 0px;
    padding-bottom: 25px;
    color: #788995;
  }
  .checkbox {
    position: relative;
    display: inline-block;
    float: left;
    margin-top: 0px; 
    margin-bottom: 0px;
    top: -4px;
    padding-right: 20px;
    @media (max-width: 500px) {
      padding-right: 10px;
    }
    input[type="checkbox"],
    input[type="radio"] {
      
      display: none;
      & + label {
        padding: 0px;
        span {
          float: left;
          width: 24px;
          height: 24px;
          margin: 0px 6px 0 0;
          vertical-align: middle;
          background: url(/assets/images/checkbox_sprite.png) left top no-repeat;
          cursor: pointer;
        }
      }
    }
    input[type="checkbox"] {
      & + label {
        span {
          background: url(/assets/images/checkbox_sprite.png) -9px -46px no-repeat;
          width: 30px;
          height: 22px;
        }
      }
      &:checked + label {
        span {
          background: url(/assets/images/checkbox_sprite.png) -51px -46px no-repeat;
        }
      }
      &:disabled + label {
        span {
          background: url(/assets/images/checkbox_sprite.png) -32px top no-repeat;
          opacity: 0.5;
        }
      }
    }
    input[type="radio"] {
      &:disabled + label {
        span {
          background: url(/assets/images/checkbox_sprite.png) left top no-repeat;
          opacity: 0.5;
        }
      }
      & + label {
        line-height: 30px;
        span {
          background: url(/assets/images/checkbox_sprite.png) 0px -4px no-repeat;
          width: 35px;
          height: 35px;
        }
      }
      &:checked + label {
        span {
          background: url(/assets/images/checkbox_sprite.png) -44px -4px no-repeat;
        }
      }
    }
  }
  @media (max-width: 500px) {
    padding:0px 30px 0px 15px;
  }
}

.paginationBox{
  padding-top: 30px;
  font-size: 14px;
  font-family: $proxima_regular;
  vertical-align: middle;
  @media (max-width: 1199px) {
    width: 70%;
    @media (max-width: 767px) {
      display: block;
      width: 100%;
    }
  }
  nav {
    text-align: right;
    @media (max-width: 767px) {
      
      margin-top: 10px;
      text-align: center;
    }
    .limits {
      display: inline-block;
      padding-right: 30px;
      b {
        margin: 0 5px;
      }
      .cls_shop_limits{
        .limit {
          margin: 0 5px;
          cursor: pointer !important;
          color:#515356;
        }
        &.active{
          .limit {
            color: #F4A619 !important;
          }
        }
      }
      span{
        display: inline-block;
        position: relative;
        top: 3px;
      }
      @media (max-width: 767px) {
        display: block;
        text-align: center;  
        padding: 0px 10px 20px;      
      }
      
    }
    p {
      display: inline-block;
      vertical-align: middle;
      font-family: $poppin_semibold;
      span {
        font-family: $poppin_regular;
        padding: 0 5px;
        font-size: 16px;
        a {
          color: $primary-font-color;
          font-family: $proxima_regular;
          &.active {
            color: $yellow_color;
          }
        }
      }
    }
    .pagination {
      display: inline-block;
      vertical-align: middle;
      margin: 0px;
      li {
        &:first-child {
          a {
            border-left: 1px solid #dedede;
          }
        }
        &:last-child {
          a {
            border-right: 1px solid #dedede;
          }
        }
        a {
          color: $primary-font-color;
          border-left: 0 none;
          border-right: 0 none;
          span.glyphicon {
            color: $yellow_color;
            font-size: 11px;
          }
          &:hover {
            cursor: pointer !important;
          }
        }
        > span.dots {
          border-right: 1px solid #dedede;
          position: relative;
          float: left;
          padding: 6px 12px;
          line-height: 1.42857143;
          z-index: 2;
          &:hover {
            background-color: transparent;
          }
        }
        &.active {
          a {
            color: $yellow_color;
            background: none;
            border-top: 1px solid #dedede;
            border-bottom: 1px solid #dedede;
            
          }
        }
        &.disabled {
          a {
            cursor: default !important;
            color: #dedede;
            span.glyphicon {
              color: #dedede;
            }
          }
        }
      }
    }
  }
}

.paymentMethodDisplay{
  padding: 30px;
  box-shadow: 0 2px 4px 0 rgba(222, 222, 222, 0.5);
  &.paymentMethodMapped{
    margin-bottom: 30px;
  }
  h4{
    padding-bottom: 20px;
    border-bottom: 1px solid #e4eaee;
    padding-bottom: 20px;
    margin-bottom: 25px;
  }
  .paymentMethod{
    span{
      float: right;
      a{
        padding-left: 20px;
        &.setdefault{
          @media (max-width: 450px) {
            display: none;
          }
        }
        @media (max-width: 550px) {
          padding-left: 10px;
        }
      }
      
    }
  }
  .checkbox {
    position: relative;
    display: inline-block;
    float: left;
    margin-top: 0px; 
    margin-bottom: 0px;
    top: -4px;
    padding-right: 20px;
    @media (max-width: 500px) {
      padding-right: 10px;
    }
    input[type="checkbox"],
    input[type="radio"] {
      
      display: none;
      & + label {
        padding: 0px;
        span {
          float: left;
          width: 24px;
          height: 24px;
          margin: 0px 6px 0 0;
          vertical-align: middle;
          background: url(/assets/images/checkbox_sprite.png) left top no-repeat;
          cursor: pointer;
        }
      }
    }
    input[type="checkbox"] {
      & + label {
        span {
          background: url(/assets/images/checkbox_sprite.png) -9px -46px no-repeat;
          width: 30px;
          height: 22px;
        }
      }
      &:checked + label {
        span {
          background: url(/assets/images/checkbox_sprite.png) -51px -46px no-repeat;
        }
      }
      &:disabled + label {
        span {
          background: url(/assets/images/checkbox_sprite.png) -32px top no-repeat;
          opacity: 0.5;
        }
      }
    }
    input[type="radio"] {
      &:disabled + label {
        span {
          background: url(/assets/images/checkbox_sprite.png) left top no-repeat;
          opacity: 0.5;
        }
      }
      & + label {
        line-height: 30px;
        span {
          background: url(/assets/images/checkbox_sprite.png) 0px -4px no-repeat;
          width: 35px;
          height: 35px;
        }
      }
      &:checked + label {
        span {
          background: url(/assets/images/checkbox_sprite.png) -44px -4px no-repeat;
        }
      }
    }
  }
  @media (max-width: 500px) {
    padding: 20px 30px 20px 15px;
  }
}

.orderHistoryDisplay{
  padding: 15px 30px;
  box-shadow: 0 2px 4px 0 rgba(222, 222, 222, 0.5);
  .orderNumber{
    font-size: 22px;
    font-family: $proxima_bold;
    color: $dark-blue-font_color;
    &:hover{
      color: $yellow_hover_color;
    }
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  .viewOrderLink{
    font-family: $poppin_regular;
    font-family: 16px;
    position: relative;
    top: 5px;
    color: $yellow_new_color;
    float: right;
    &:hover{
      color: $yellow_hover_color;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
  }
  .orderHistoryDetails{
    padding: 10px 30px;
    @media (max-width: 767px) {
      padding:10px 0px;
      font-size: 13px;
    }
    .line{
      padding-bottom:10px;
      span{
        font-family: $proxima_bold;
        color: $grey-title-font-color;
        display: inline-block;
        min-width: 80px;
      }
    }
    &:last-child {
      padding-bottom:0px;
    }
  }
}

.orderHistoryBox{
  &:last-child {
    box-shadow: none;
  }
}
.btn_line{
  text-align: right;
  font-size: 18px;
  a{
    margin: 0 0px 0px 20px;
  }
}
a.action{
  color: $yellow_color;
  cursor: pointer;
  &:hover{
    color: $yellow_hover_color
  }
  &.action_disabled{
    color: $grey-title-font-color;
  }
}

.userActions{
  float: right;
  font-size: 16px;
}
.poppin_regular{
  font-family: $poppin_regular;
}
.proxima_regular{
  font-family: $proxima_regular; 
};

.poppin_semibold{
  font-family: $poppin_semibold;
}
.modal-backdrop.in{
  opacity: .2 !important; 
  filter: alpha(opacity=20);
}
#payment_form{
  label{
    //color:$grey-other-font-color;
  }
}
.navsep{
  margin-top: 10px;
  border-top: 1px solid #EBEDF2;
  padding-top: 8px;
}

  .clsproduct_added_cart_confirmation {
    text-align: center;
    padding-right: 0px !important;
    .vertical-alignment-helper {
      display: table;
      height: 100%;
      width: 100%;
    }
    .vertical-align-center {
      /* To center vertically */
      display: table-cell;
      vertical-align: middle;
      @media (max-width: 767px) {
        width: 95%;
      }
      .modal-content {
        width: inherit;
        height: inherit;
        /* To center horizontally */
        margin: 0 auto;
        .modal-header {
          padding: 25px;
        }
        .modal-body {
          padding: 30px;
          a {
            font-size: 18px;
          }
          .clsyellow_btn {
            @include yellowbutton();
            margin-left: 40px;
            @media (max-width: 499px) {
              display: block;
              width: 100%;
              margin-top: 10px;
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
  .carteditquantity{
    margin-left: 10px;
    display: inline-block;
    img{
      position: relative;
      top: -4px;
    }
  }
  .productEditModal {
    .modal-title{
      text-align: left;
      h2{
        color: #02416e;
        font-family: $poppin_semibold;
        font-size: 22px;
        margin-bottom: 20px;
      }
      h4{
        font-size: 20px;
        color: #f4a619;
        font-family: $proxima_regular;
        margin-bottom: 25px;
        span{
          font-size: 14px;
          color: $grey-title-font-color;
        }
      }
      input{
        border: 1.5px solid #f4a619;
        color: #000;
      }
    }
    
  }
.doomsday-page {
  background-color: #ffffff;
  .container {
    width: 100%;
    max-width: 640px;
    margin: 40px auto;
    p {
      color: #02416E;
      font-size: 15px;
      line-height: 1.4em;
      margin: 1.4em 0px;
      font-family: poppinsregular;
      b {
        font-family: poppinsbold;
        font-weight: normal;
      }
      a {
        color: #02416E;
        text-decoration: underline;
        transition: color 0.3s;
        &:hover {
          color: #dd8e00;
        }
      }
    }
  }
}
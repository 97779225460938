
/* End hide from IE-mac */
/*Ending Common CSS Coading*/
@mixin form_control_style {
  height: 40px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 14px 17px;
  border-color: #e5e5e5;
  font-size: 18px;
  font-family: $proxima_regular;
  color: #02416E;
  white-space: nowrap;
  text-overflow: ellipsis;
  &::-moz-placeholder {
    color: $grey-title-font-color;
  }
  &:-ms-input-placeholder {
    color: $grey-title-font-color;
  }
  &::-webkit-input-placeholder {
    color: $grey-title-font-color;
  }
}

@mixin yellowbutton {
  height: 50px;
  border-radius: 8px;
  background-color: #f4a619;
  border-color: #f4a619;
  // box-shadow: 0 2px 2px 0 #F4A619;
  // -webkit-box-shadow: 0 2px 2px 0 #F4A619;
  color: #fff;
  padding: 15px 25px;
  font-family: $poppin_regular;
  font-weight: 500;
  font-size: 16px;
  &:hover,
  &:focus {
    border-color: #dd8e00;
    background-color: #dd8e00;
    // box-shadow: 0 2px 2px 0 #dd8e00;
    // -webkit-box-shadow: 0 2px 2px 0 #dd8e00;
  }
}

@mixin greybutton {
  @include yellowbutton();
  background-color: #ebedf2;
  border-color: #ebedf2;
  color: #919498;
  &:hover,
  &:focus {
    border-color: #d2d2d2;
    background-color: #d2d2d2;
  }
}

@mixin clslist_sort_style {
  .btn-group.bootstrap-select {
    width: 100%;
    .dropdown-toggle.selectpicker {
      @include form_control_style();
      padding: 0px 17px;
      span.glyphicon {
        color: #f5a623;
        font-size: 11px;
        right: 10px;
      }
      &:hover,
      &:focus,
      &:active {
        background: #fff;
      }
    }
    &.open {
      .dropdown-toggle.selectpicker {
        background: #fff;
      }
    }
    .dropdown-menu.open {
      .dropdown-menu.inner.selectpicker {
        max-height: 300px !important;
        font-size: 16px;
        li {
          a {
            padding: 10px 20px;
            border-bottom: 1px solid #eee;
            &:last-child {
              border-bottom: 0 none;
            }
          }
        }
      }
    }
  }
}

@mixin h1_style {
  color: $blue-font_color;
  font-size: 44px;
  font-family: $poppin_bold;
  @media (max-width: 767px) {
    font-family: $poppin_regular;
    font-size: 27px;
  }
}

@mixin h2_style {
  @include h1_style();
  font-family: $poppin_semibold;
  font-size: 34px;
}

@mixin whitebox {
  border: 1px solid #f2f3f5;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 #bccfdd;
  -webkit-box-shadow: 0 1px 3px 0 #bccfdd;
}

@mixin label_style {
  color: $grey-other-font-color;
  font-family: $proxima_regular;
  font-weight: normal;
  letter-spacing: 1px;
}